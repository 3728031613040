import { Button, Col, ColorPicker, DatePicker, Form, Input, InputNumber, Radio, Row, Select } from "antd"
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import { getWellnessClassDataForCreateLectureList } from "../../../../../service/wellnessClass";
import { updateIssuedWellnessTicketDetail } from "../../../../../service/issuedWellnessTicket";
import { wellnesTicketFrequencyUnitToKr } from "../../../model";

interface IProps {
    selectedIssuedWellnessTicketList: IissuedWellnessTicketWithMemberInfoAdminResponse[]
    refresh: Function
}

const IssuedWellnessTicketUpdate = ({ selectedIssuedWellnessTicketList, refresh }: IProps) => {
    const [form] = Form.useForm();
    const [type, setType] = useState<"COUNT" | "PERIOD">("COUNT");
    const [frequencyUnit, setFrequencyUnit] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY" | "NOLIMIT">();
    const [wellnessClassList, setWellnessClassList] = useState<Array<IWellnessClassDataForCreateLectureListResponse> | undefined>(undefined);
    const [isMulti, setIsMulti] = useState<boolean>(selectedIssuedWellnessTicketList.length > 1);
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);

    useEffect(() => {
        setIsMulti(selectedIssuedWellnessTicketList.length > 1)
    }, [selectedIssuedWellnessTicketList])

    useEffect(() => {
        getWellnessClassDataForCreateLectureList()
            .then(res => setWellnessClassList(res.data))
            .catch(err => console.error('err', err))
    }, [])

    const onFinish = (values: IissuedWellnessTicketWithMemberInfoAdminResponse) => {
        setUpdateLoading(true)
        console.log('expireDate', values.expireDate)
        let body: IIssuedWellnessTicketUpdateDetailAdminRequestV2 = {
            idList: selectedIssuedWellnessTicketList.map(i => i.id),
            wellnessTicketId: selectedIssuedWellnessTicketList[0].wellnessTicketId,
            name: values.name,
            type: values.type,
            frequencyUnit: values.frequencyUnit,
            frequencyUsableCnt: values.frequencyUsableCnt,
            startColor: values.startColor,
            endColor: values.endColor,
            textColor: values.textColor
        };
        if (selectedIssuedWellnessTicketList.length === 1) {
            body = {
                idList: [selectedIssuedWellnessTicketList[0].id],
                wellnessTicketId: selectedIssuedWellnessTicketList[0].wellnessTicketId,
                name: values.name,
                type: values.type,
                frequencyUnit: values.frequencyUnit,
                frequencyUsableCnt: values.frequencyUsableCnt,
                remainingCnt: values.remainingCnt,
                totalUsableCnt: values.totalUsableCnt,
                expireDate: values.expireDate ? dayjs(values.expireDate).format('YYYY-MM-DDT23:59:59Z') : selectedIssuedWellnessTicketList[0].expireDate,
                startColor: values.startColor,
                endColor: values.endColor,
                textColor: values.textColor
            }
        }

        updateIssuedWellnessTicketDetail(body)
            .then(res => res.data && refresh())
            .catch(err => console.error('err', err))
            .finally(() => setUpdateLoading(false))
    }

    return (<>
        <div style={{ fontSize: 18, marginBottom: 10, fontWeight: 600 }}>{selectedIssuedWellnessTicketList.length === 1 ? '정기권 수정' : `${selectedIssuedWellnessTicketList.length}개 정기권 수정`}</div>
        <Form
            form={form}
            labelCol={{ span: 6 }}
            labelAlign="left"
            wrapperCol={{ span: 18 }}
            onFinish={onFinish}
            initialValues={{ ...selectedIssuedWellnessTicketList[0], expireDate: dayjs(selectedIssuedWellnessTicketList[0].expireDate) }}
        >
            <Form.Item label="종류" name="type" rules={[{ required: true, message: '필수 선택값입니다.' }]} initialValue={"COUNT"}>
                <Radio.Group value={type} onChange={q => setType(q.target.value)}>
                    <Radio value="COUNT">횟수권</Radio>
                    <Radio value="PERIOD">기간권</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item label="이름" name="name" rules={[{ required: true, message: '필수 입력값입니다.' }]}>
                <Input placeholder="정기권 이름을 입력해 주세요" />
            </Form.Item>
            <Form.Item label="이용 제한">
                <Form.Item name="frequencyUnit">
                    <Radio.Group onChange={e => setFrequencyUnit(e.target.value)}>
                        <Radio value="YEAR">연</Radio>
                        <Radio value="MONTH">월</Radio>
                        <Radio value="WEEK">주</Radio>
                        <Radio value="DAY">일</Radio>
                        <Radio value={"NOLIMIT"}>없음</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="frequencyUsableCnt">
                    <InputNumber style={{ width: '100%' }} controls={false} min={0} prefix={<span style={{ marginRight: '15px' }}>{wellnesTicketFrequencyUnitToKr(frequencyUnit)}</span>} suffix="회 수업 예약 가능" disabled={frequencyUnit === 'NOLIMIT'} />
                </Form.Item>
            </Form.Item>
            {!isMulti && <>
                {type === "COUNT" && <>
                    <Form.Item label="사용 가능 횟수" name="totalUsableCnt" rules={[{ required: type === 'COUNT' && true, message: '숫자만 입력해 주세요.' }]}>
                        <InputNumber style={{ width: '100%' }} controls={false} min={0} placeholder="숫자만 입력해 주세요" />
                    </Form.Item>
                    <Form.Item label="잔여 횟수" name="remainingCnt" rules={[{ required: type === 'COUNT' && true, message: '숫자만 입력해 주세요.' }]}>
                        <InputNumber style={{ width: '100%' }} controls={false} min={0} placeholder="숫자만 입력해 주세요" />
                    </Form.Item>
                </>}

                <Form.Item label="사용 기한(일)" name="expireDate" rules={[{ required: true, message: '필수 입력값입니다.' }]}>
                    <DatePicker />
                </Form.Item>
            </>}

            <Form.Item label="정기권 색상" style={{ marginBottom: 0 }} >
                <Form.Item name="startColor" label="시작 배경색" initialValue={'#1677FF'} style={{ display: 'inline-block', width: '120px' }} getValueFromEvent={q => q.toHexString()}>
                    <ColorPicker format="hex" />
                </Form.Item>
                <Form.Item name="endColor" label="종료 배경색" initialValue={'#1677FF'} style={{ display: 'inline-block', width: '120px' }} getValueFromEvent={q => q.toHexString()}>
                    <ColorPicker format="hex" />
                </Form.Item>
                <Form.Item name="textColor" label="텍스트 색상" initialValue={'#1677FF'} style={{ display: 'inline-block', width: '120px' }} getValueFromEvent={q => q.toHexString()}>
                    <ColorPicker format="hex" />
                </Form.Item>
            </Form.Item>

            <Form.Item label colon={false} style={{ width: '100%', height: '60px', marginBottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                <div style={{ display: 'flex', gap: '15px' }}>
                    <Button type="primary" htmlType="submit" loading={updateLoading}>수정</Button>
                </div>
            </Form.Item>
        </Form>
    </>
    )
}

export default IssuedWellnessTicketUpdate