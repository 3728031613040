import TicketRetentionReportChart from "./ui/chart";
import TicketRetentionReportTable from "./ui/table";
import { useEffect, useState } from "react";
import { getReportTicketRetention } from "../../../../service/report/ticket";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";

const ReportTicketRetention = () => {
    const [ticketRetentionData, setTicketRetentionData] = useState<Array<ITicketRetentionData>>([]);
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);

    useEffect(() => {
        requestReportTicketRetention()
    }, [])


    const requestReportTicketRetention = () => {
        getReportTicketRetention()
            .then((res) => setTicketRetentionData(res.data))
            .catch(error => console.error('error', error))
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        {ticketRetentionData.length > 0 && <>
            <TicketRetentionReportChart data={ticketRetentionData} />
            <TicketRetentionReportTable data={ticketRetentionData} />
        </>}
    </>
}

export default ReportTicketRetention;