interface IProps {
    text?: string
    style?: React.CSSProperties;
}

const TextBadge = ({ text, style }: IProps) => {
    const tagStyle: React.CSSProperties = {
        width: 'auto',
        height: '24px',
        padding: '4px 8px',
        borderRadius: '4px',
        backgroundColor: '#f4f4f4',
        lineHeight: '14.32px',
        fontSize: '12px'
    };

    return <div style={{ ...tagStyle, ...style }}>{text}</div>
};

export { TextBadge };