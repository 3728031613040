import MinimalLayout from '../layout/MinimalLayout';
import CheckIn from '../pages/checkIn';
import Login from '../pages/login';
import KakaoLoginCallback from '../pages/login/kakaoLoginCallback';
import NaverLoginCallback from '../pages/login/naverLoginCallback';

const LoginRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        { path: '/login', element: <Login /> },
        { path: '/check-in', element: <CheckIn /> },
        { path: '/kakaologin/callback', element: <KakaoLoginCallback /> },
        { path: '/naverlogin/callback', element: <NaverLoginCallback /> }
    ]
};

export default LoginRoutes;