import { AxiosResponse } from 'axios';
import api from '../Api'

export const getReportSalesDaily = (startDate: string, endDate: string): Promise<AxiosResponse<Array<ISalesReportData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/sales/daily?startDate=${startDate}&endDate=${endDate}`)
}

export const getReportSalesWeekly = (startDate: string, endDate: string): Promise<AxiosResponse<Array<ISalesReportData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/sales/weekly?startDate=${startDate}&endDate=${endDate}`)
}

export const getReportSalesMonthly = (startDate: string, endDate: string): Promise<AxiosResponse<Array<ISalesReportData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/sales/monthly?startDate=${startDate}&endDate=${endDate}`)
}