import { useEffect, useState } from "react";
import { deleteWellnessTicketGroupByWellnessTicketGroupId, getUpdateDataForWellnessTicketGroupByWellnessTicketGroupId, updateWellnessTicketGroupAndOrderNumberByWellnessTicketGroupUpdateRequest } from "../../../service/wellnessTicketGroup";
import { Button, Checkbox, Form, Input, InputNumber, Select } from "antd";
import DragAbleTable from "../../../components/DataTable/DragAble";
import { useNavigate, useParams } from "react-router-dom";
import { wellnessTicketGroupUpdateColumns } from "../model";
import { getAllWellnessTicketByCompanyId } from "../../../service/wellnessTicket";
import { RootState } from "../../../modules";
import { useSelector } from "react-redux";

const WellnessTicketGroupUpdate = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    const { companyId } = useSelector((state: RootState) => state.selectedCenter);
    const [wellnessTicketList, setWellnessTicketList] = useState<IWellnessTicketForListAdminResponse[]>([]);
    const [getLoading, setGetLoading] = useState<boolean>(false);
    const [updateLoading, setUpdateLoading] = useState<boolean>(false);
    const [wellnessTicketGroup, setWellnessTicketGroup] = useState<IWellnessTicketGroupUpdateAdminResponse>();
    const [dataSource, setDataSource] = useState<IWellnessTicketGroupOrderNumberUpdateResponse[]>([]);

    useEffect(() => {
        if (!companyId) return;
        setGetLoading(true);
        getAllWellnessTicketByCompanyId(companyId)
            .then(res => setWellnessTicketList(res.data.map(q => ({ ...q, key: q.id }))))
            .catch(err => console.log(err))
            .finally(() => setGetLoading(false))

    }, [companyId])

    useEffect(() => {
        if (!id || wellnessTicketList.length <= 0) return;
        setGetLoading(true);
        getUpdateDataForWellnessTicketGroupByWellnessTicketGroupId(id)
            .then(res => { setWellnessTicketGroup(res.data); handleTicketSelection(res.data.wellnessTicketGroupOrderNumberList.map(q => q.wellnessTicketId)) })
            .catch(err => console.log(err))
            .finally(() => setGetLoading(false))
    }, [id, wellnessTicketList.length])

    const handleTicketSelection = (wellnessTicketIdList: number[]) => {
        if (!wellnessTicketList) return;
        const initialOrderNumbers = wellnessTicketList.filter(q => wellnessTicketIdList.includes(q.id))
        setDataSource(initialOrderNumbers.map((q, idx) => ({ ...q, key: idx + 1, wellnessTicketId: q.id, orderNumber: idx })))
    };

    const deleteButtonOnClick = (id: number) => {
        if (window.confirm("삭제 하면 되돌릴 수 없습니다. \n삭제 하시겠습니까?")) {
            deleteWellnessTicketGroupByWellnessTicketGroupId(id)
                .then(res => { console.log(res.data); navigate("/wellness-ticket-group") })
                .catch(err => console.log(err))
        }
    }

    const onFinish = (values: IWellnessTicketGroupBasicAdminRequest) => {
        if (!wellnessTicketGroup) return;
        values.id = wellnessTicketGroup.id
        values.wellnessTicketGroupOrderNumberList = dataSource.map(q => ({ wellnessTicketId: q.wellnessTicketId, orderNumber: q.orderNumber }))
        setUpdateLoading(true);
        updateWellnessTicketGroupAndOrderNumberByWellnessTicketGroupUpdateRequest(values)
            .then(res => console.log(res.data))
            .catch(err => console.log(err))
            .finally(() => { setUpdateLoading(false); navigate('/wellness-ticket-group') })
    };
    return <>
        {wellnessTicketGroup &&
            <Form
                onFinish={onFinish}>
                <Form.Item
                    initialValue={wellnessTicketGroup.title}
                    label="그룹 이름"
                    name="title"
                    rules={[{ required: true, message: '필수 입력값입니다.' }]}
                >
                    <Input placeholder="그룹 이름을 입력해주세요." />
                </Form.Item>
                <Form.Item
                    initialValue={wellnessTicketGroup.description}
                    label="그룹 설명"
                    name="description"
                    rules={[{ required: true, message: '필수 입력값입니다.' }]}
                >
                    <Input.TextArea placeholder="그룹 설명을 입력해주세요." />
                </Form.Item>
                <Form.Item
                    initialValue={wellnessTicketGroup.idx}
                    label="그룹 순서"
                    name="idx"
                    rules={[{ required: true, message: '필수 입력값입니다.' }]}
                >
                    <InputNumber placeholder="오름차순으로 표시 됩니다." />
                </Form.Item>
                <Form.Item label="포함 정기권" >
                    <Select
                        mode="multiple"
                        placeholder="정기권 선택"
                        onChange={handleTicketSelection}
                        loading={getLoading}
                        defaultValue={wellnessTicketGroup?.wellnessTicketGroupOrderNumberList.map(q => q.wellnessTicketId)}
                        options={wellnessTicketList.map(q => ({ value: q.id, label: q.name, }))}
                    />
                </Form.Item>
                <DragAbleTable columns={wellnessTicketGroupUpdateColumns} dataSource={dataSource} setDataSource={setDataSource} targetName='orderNumber' tableOption={{ scroll: { y: 1000 }, pagination: false }} />
                <Form.Item
                    initialValue={wellnessTicketGroup.isDisplay}
                    style={{ marginTop: '15px' }}
                    label="표시 여부"
                    name="isDisplay"
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item label colon={false} style={{ width: '100%', height: '60px', marginBottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                    <div style={{ display: 'flex', gap: '25px' }}>
                        <Button type="primary" htmlType="submit" loading={updateLoading}>수정</Button>
                        <Button type="default" htmlType="button" onClick={() => navigate(-1)}>취소</Button>
                        <Button type="primary" danger onClick={() => deleteButtonOnClick(Number(wellnessTicketGroup.id))}>삭제</Button>
                    </div>
                </Form.Item>
            </Form>}
    </>
}

export { WellnessTicketGroupUpdate };