import { Table } from "antd"
import { TableProps } from "antd/lib";

interface IProps {
    data: Array<ITicketConversionData>
}

interface DataType {
    key: string
    ticketId: number
    total: number
    18: number
    54: number
    23: number
    24: number
    25: number
    26: number
    5: number
    6: number
    7: number
    8: number
    19: number
    55: number
    59: number
    60: number
    61: number
    62: number
    [key: number]: number;
    conversionRate: number;
}

const TICKETNAME = (id: number): string => {
    return ticketNames[id] || '알수 없음';
};
const ticketNames: { [key: number]: string } = {
    18: 'ALL 1',
    54: 'ALL 4',
    23: 'ALL 10',
    24: 'ALL 20',
    25: 'ALL 40',
    26: 'ALL 60',
    5: '강남 1',
    6: '강남 10',
    7: '강남 20',
    8: '강남 40',
    19: '강남 60',
    55: '강남 TTC',
    59: '선릉・도산 1',
    60: '선릉・도산 4',
    61: '선릉・도산 10',
    62: '선릉・도산 20',
};
const ticketIdList = [18, 54, 23, 24, 25, 26, 5, 6, 7, 8, 19, 55, 59, 60, 61, 62];

const columns: TableProps<DataType>['columns'] = [
    {
        title: '정기권',
        dataIndex: 'ticketId',
        key: 'ticketId',
        render: (value) => TICKETNAME(value),
        sorter: (a, b) => TICKETNAME(a.ticketId).localeCompare(TICKETNAME(b.ticketId)),
    },
    {
        title: '정기권',
        children: ticketIdList.map((ticketId: number) => ({
            title: TICKETNAME(ticketId),
            dataIndex: ticketId,
            key: ticketId
        }))
    },
    {
        title: '미전환',
        dataIndex: 0,
        key: 0,
        sorter: (a, b) => a[0] - b[0],
    },
    {
        title: '전환율',
        dataIndex: 'conversionRate',
        key: 'conversionRate',
        render: (q) => `${q.toLocaleString()}%`,
        sorter: (a, b) => a.conversionRate - b.conversionRate,
    },
    {
        title: '합계',
        dataIndex: 'total',
        key: 'total',
        sorter: (a, b) => a.total - b.total,
    },

];


const TicketSalesConversionReportTable = ({ data }: IProps) => {
    const dataConvert = ticketIdList.map((ticketId: number) => {
        const baseTicketIdData = data.find((e: ITicketConversionData) => e.id === ticketId);

        const cntList = [...ticketIdList, 0].reduce((acc: any, tId: number) => {
            const cnt = baseTicketIdData?.conversion.find((i: any) => i.id === tId)?.cnt;
            if (cnt !== undefined) {
                acc[tId] = cnt;
            }
            return acc;
        }, {});
        //@ts-ignore
        const total = Object.values(cntList).reduce((sum: number, value: number) => sum + value, 0);

        return {
            key: ticketId,
            'ticketId': ticketId,
            ...cntList,
            total: total,
        }
    })

    const tableData = dataConvert.map((data: any) => ({
        ...data,
        conversionRate: Math.round((data.total - data[0]) / data.total * 100)
    }))

    return <>
        전환율
        <Table
            size="small"
            columns={columns}
            dataSource={tableData}
            pagination={false}
            bordered
        />
    </>
}
export default TicketSalesConversionReportTable