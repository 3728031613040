import { Button, Input, Modal, message } from "antd";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { getSuperAdminAsync } from "../../modules/superAdmin";

const SuperAdminPassword = () => {
    const dispatch = useDispatch();
    const update = getSuperAdminAsync;
    const [isOpen, setIsOpen] = useState<boolean>(true);
    const [inputPassword, setInputPassword] = useState<string>('');

    const inputPasswordCheckButtonClick = (() => {
        if (!inputPassword) return;
        if (inputPassword === "311") {
            dispatch(update({ isSuperAdmin: true }))
        } else {
            message.error("비밀번호가 틀렸습니다.")
        }
    })

    return <>
        <Modal width={"600px"} style={{ margin: "15% auto" }} open={isOpen} onCancel={() => setIsOpen(false)} footer={false}>
            <div style={{ margin: "5%" }}>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "20%" }}>비밀번호 입력</div>
                    <Input.Password style={{ width: "80%" }} onChange={(e) => setInputPassword(e.target.value)} />
                </div>
                <div style={{ display: "flex", flexDirection: "row-reverse", marginTop: "5%" }}>
                    <Button style={{ width: "10%" }} onClick={inputPasswordCheckButtonClick}>확인</Button>
                    <Button style={{ width: "10%" }} onClick={() => setIsOpen(false)}>취소</Button>
                </div>
            </div>
        </Modal>
    </>
}

export default SuperAdminPassword;