import { Button, Spin, Switch, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../modules";
import SettingCompanyYogaCenter from "../../components/SettingCompanyYogaCenter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAllWellnessTicketByCompanyId } from "../../service/wellnessTicket";
import { wellnessTicketColumns } from "./model";

const WellnessTicket = () => {
    const navigate = useNavigate()
    const { companyId } = useSelector((state: RootState) => state.selectedCenter);
    const { companyLoading } = useSelector((state: RootState) => state.company.companyList);
    const [wellnessTicketForList, setWellnessTicketForList] = useState<IWellnessTicketForListAdminResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [isDeleteFilter, setIsDeleteFilter] = useState(true);


    useEffect(() => {
        if (!(companyId)) return;
        setLoading(true);
        getAllWellnessTicketByCompanyId(companyId)
            .then((res) => setWellnessTicketForList(res.data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, [])

    return (<>
        {companyLoading ? <Spin /> : <SettingCompanyYogaCenter onlyCompany={true} />}
        {companyId && <>
            <div style={{ textAlign: "right", marginBottom: '20px' }}>
                <Button style={{ marginRight: '15px' }} disabled={companyId === undefined} onClick={() => navigate('/wellness-ticket-group')}>정기권 그룹</Button>
                <Button disabled={companyId === undefined} onClick={() => navigate('/wellness-ticket/create')}>정기권 생성</Button>
            </div>
        </>}

        <div style={{ textAlign: 'right', marginBottom: '5px' }}>
            <Switch defaultChecked={isDeleteFilter} value={isDeleteFilter} onChange={setIsDeleteFilter} checkedChildren="삭제" unCheckedChildren="삭제" />
        </div>
        <Table
            columns={wellnessTicketColumns}
            dataSource={wellnessTicketForList?.filter(q => isDeleteFilter ? q.isDelete === false : true)
                .map(q => ({ ...q, key: q.id }))}
            loading={loading} />
    </>)
}

export default WellnessTicket;