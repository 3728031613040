import dayjs from "dayjs";
import DateRangePicker from "../../ui/dateRangePicker";
import MemberReportTimeChart from "./ui/chart";
import MemberReportTimeTable from "./ui/table";
import { requestRegisterCountAndPaidCountAndPriceToHourByCreatedDateBetween } from "../../../../service/report";
import { useEffect, useState } from "react";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";

const ReportMemberTime = () => {
    const [data, setData] = useState<any[]>([]);
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);

    const startDate = dayjs().subtract(7, 'week')
    const requestReportMember = (startDate: string, endDate: string) => {
        requestRegisterCountAndPaidCountAndPriceToHourByCreatedDateBetween(startDate, endDate)
            .then(res => { setData(res.data); })
            .catch(err => { console.log(err); });
    }
    const mergeData = (data: any[]): any[] => {
        const merged = data.reduce((acc: any[], current) => {
            const existing = acc.find(item => item.name === current.name && item.hour === current.hour);
            if (existing) {
                existing.count += current.count;  // count를 합침
            } else {
                acc.push({ ...current });  // acc 배열에 추가
            }
            return acc;
        }, []);
        return merged;
    };
    const groupDataBySignDay = (data: any[]) => {
        const paymentNameList = ['tossPaymentCount', 'onSitePaymentSEOLLEUNGCount', 'onSitePaymentGANGNAMCount', 'onSitePaymentDOSANCount', 'onSitePaymentEtcCount']
        const grouped: { [key: string]: any } = {};
        data.forEach(item => {
            if (!grouped[item.signHour]) {
                grouped[item.signHour] = {
                    hour: item.signHour,
                    registerCount: 0,
                    tossPaidCount: 0,
                    tossPaidPrice: 0,
                    SEOLLEUNGPaidCount: 0,
                    SEOLLEUNGPaidPrice: 0,
                    GANGNAMPaidCount: 0,
                    GANGNAMPaidPrice: 0,
                    DOSANPaidCount: 0,
                    DOSANPaidPrice: 0,
                    EtcPaidCount: 0,
                    EtcPaidPrice: 0,
                    paidPriceRatio: 0,
                };
            }
        })
        if (!grouped['Total']) {
            grouped['Total'] = {
                hour: 'Total',
                registerCount: data.filter(q => q.name === 'newAccount').reduce((acc, cur) => acc + (cur.registerCount ?? 0), 0),
                paidPriceRatio: '-',
                tossPaidCount: null,
                tossPaidPrice: null,
                SEOLLEUNGPaidCount: null,
                SEOLLEUNGPaidPrice: null,
                GANGNAMPaidCount: null,
                GANGNAMPaidPrice: null,
                DOSANPaidCount: null,
                DOSANPaidPrice: null,
                EtcPaidCount: null,
                EtcPaidPrice: null,
            }
        }
        data.forEach(item => {
            if (item.name === 'newAccount') { grouped[item.signHour].registerCount = item.registerCount || 0; }

            switch (item.name) {
                case 'tossPaymentCount':
                    grouped[item.signHour].tossPaidCount = item.paidCount || 0;
                    grouped[item.signHour].tossPaidPrice = item.paidPrice || 0;
                    grouped['Total'].tossPaidCount += item.paidCount || 0;
                    grouped['Total'].tossPaidPrice += item.paidPrice || 0;
                    break;
                case 'onSitePaymentSEOLLEUNGCount':
                    grouped[item.signHour].SEOLLEUNGPaidCount = item.paidCount || 0;
                    grouped[item.signHour].SEOLLEUNGPaidPrice = item.paidPrice || 0;
                    grouped['Total'].SEOLLEUNGPaidCount += item.paidCount || 0;
                    grouped['Total'].SEOLLEUNGPaidPrice += item.paidPrice || 0;
                    break;
                case 'onSitePaymentGANGNAMCount':
                    grouped[item.signHour].GANGNAMPaidCount = item.paidCount || 0;
                    grouped[item.signHour].GANGNAMPaidPrice = item.paidPrice || 0;
                    grouped['Total'].GANGNAMPaidCount += item.paidCount || 0;
                    grouped['Total'].GANGNAMPaidPrice += item.paidPrice || 0;
                    break;
                case 'onSitePaymentDOSANCount':
                    grouped[item.signHour].DOSANPaidCount = item.paidCount || 0;
                    grouped[item.signHour].DOSANPaidPrice = item.paidPrice || 0;
                    grouped['Total'].DOSANPaidCount += item.paidCount || 0;
                    grouped['Total'].DOSANPaidPrice += item.paidPrice || 0;
                    break;
                case 'onSitePaymentEtcCount':
                    grouped[item.signHour].EtcPaidCount = item.paidCount || 0;
                    grouped[item.signHour].EtcPaidPrice = item.paidPrice || 0;
                    grouped['Total'].EtcPaidCount += item.paidCount || 0;
                    grouped['Total'].EtcPaidPrice += item.paidPrice || 0;
                    break;
                default:
                    break;
            }
        });
        Object.values(grouped).forEach(item => {
            if (item.hour === 'Total') return;
            grouped[item.hour].paidPriceRatio = ((item.tossPaidPrice + item.SEOLLEUNGPaidPrice + item.GANGNAMPaidPrice + item.DOSANPaidPrice + item.EtcPaidPrice) / data.map((q => paymentNameList.includes(q.name) ? q.paidPrice : 0)).reduce((acc, num) => acc + num, 0) * 100).toFixed(2) + '%';
        })
        return Object.values(grouped).sort((a, b) => a.hour.localeCompare(b.hour));
    };
    useEffect(() => {
        requestReportMember(startDate.format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    }, [])

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }


    return <>
        <DateRangePicker
            startDate={startDate}
            onClick={(startDate: string, endDate: string) => requestReportMember(startDate, endDate)} />
        <br /><br />
        <MemberReportTimeChart value={mergeData(data.map(q => ({ name: q.name === 'newAccount' ? q.name : 'total', count: q.registerCount ?? q.paidCount, hour: q.signHour })))} />
        <br /><br />
        <MemberReportTimeTable data={groupDataBySignDay(data).map((q, idx) => ({ ...q, key: idx }))} />
    </>
}
export default ReportMemberTime