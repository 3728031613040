import { Pie } from '@ant-design/plots';

interface IProps {
    data: Array<ITicketRetentionData>
}

const ticketNames: { [key: number]: string } = {
    18: 'ALL PASS 1회권',
    54: 'ALL PASS 4회권',
    23: 'ALL PASS 10회권',
    24: 'ALL PASS 20회권',
    25: 'ALL PASS 40회권',
    26: 'ALL PASS 60회권',
    5: '강남 스튜디오 1회권',
    6: '강남 스튜디오 10회권',
    7: '강남 스튜디오 20회권',
    8: '강남 스튜디오 40회권',
    19: '강남 스튜디오 60회권',
    55: '강남 스튜디오 히말라야 빈야사 TTC',
    59: '선릉・도산 1회권',
    60: '선릉・도산 4회권',
    61: '선릉・도산 10회권',
    62: '선릉・도산 20회권',
};

const TICKETNAME = (id: number): string => {
    return ticketNames[id] || '알수 없음';
};

const TicketRetentionReportChart = ({ data }: IProps) => {
    const chartData = data.map((e: ITicketRetentionData) => ({
        type: TICKETNAME(e.id),
        value: e.active_tickets
    }))

    const config = {
        data: chartData,
        angleField: 'value',
        colorField: 'type',
        radius: 0.8,
        label: {
            text: (d: { value: number, type: string }) => `${d.type}: ${d.value}`,
            position: 'spider',
        },
        legend: {
            color: {
                title: false,
                position: 'right',
                rowPadding: 5,
            },
        },
    };
    return <Pie {...config} />;
}

export default TicketRetentionReportChart