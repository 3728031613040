import { ColumnsType } from "antd/es/table";
import { ReactComponent as CircleCheck } from "../../../assets/images/icon/circle-check.svg";
import { TextBadge } from "../../../components/badge/textBadge";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";

export const wellnessTicketGroupColumns: ColumnsType<IWellnessTicketGroupListAdminResponse> = [
    {
        title: '제목',
        dataIndex: 'title',
        render: (q, record) => <Link to={`/wellness-ticket-group/update/${record?.id}`}>{q}</Link>,
    },
    {
        title: '설명',
        dataIndex: 'description',
        onCell: q => ({ style: { whiteSpace: 'pre-wrap' } }),
    },
    {
        title: '웰니스 정기권 목록',
        dataIndex: 'wellnessTicketGroupOrderNumberList',
        render: (q: IWellnessTicketGroupOrderNumberForWellnessTicketGroupAdmin[]) => <Row>{q?.map((w, idx) =>
            <Col key={idx} xs={23} lg={11} xl={7} xxl={5} offset={1} >
                <div style={{ textDecorationLine: w.isDisplay ? '' : 'line-through', border: '1px solid black', marginTop: 10, borderRadius: '4px', padding: '5px', background: `linear-gradient(135deg, ${w.startColor}, ${w.endColor})`, color: w.textColor, }}>{w.name}</div>
            </Col>
        )}
        </Row>
    },
    {
        title: '표시 여부',
        dataIndex: 'isDisplay',
        render: isDisplay => isDisplay ? "표시" : "숨김",
        onCell: q => ({ style: { color: q?.isDisplay ? 'green' : "black" } })
    },
    {
        title: '순서',
        dataIndex: 'idx',
    },
]


export const wellnessTicketGroupCreateColumns: ColumnsType<IWellnessTicketForWellnessTicketGroupOrderNumber> = [
    {
        title: 'id',
        dataIndex: 'id',
    },
    {
        title: '별칭',
        dataIndex: 'name',
    },
    {
        title: '미리보기',
        width: 300,
        render: (q: any, w) => <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', padding: '16px', border: '1px solid #d9d9d9', borderRadius: '4px', background: `linear-gradient(135deg, ${w.startColor}, ${w.endColor})`, color: w.textColor, }}>
            <div style={{ width: '100%', marginRight: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '18px', lineHeight: '120%' }}>
                        {w.name}
                    </div>
                    <div>
                        {w.type === 'COUNT' ? '횟수권' : '기간권'}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                    <div style={{ lineHeight: " 24px", color: '#676767' }}>{w.discountedPrice?.toLocaleString()}원 </div>
                    {w.discountedValue > 0 && <TextBadge text={w.discountType === 'FIXED' ? '-' + w.discountedValue?.toLocaleString() : w.discountedValue + '%'} style={{ backgroundColor: '#000000', color: '#FFFFFF', marginLeft: '4px' }} />}
                </div>
            </div>
            <CircleCheck width={20} height={20} stroke={"#AEAEAE"} fill={'white'} />
        </div>
    },
    {
        title: '표시 여부',
        dataIndex: 'isDisplay',
        render: isDisplay => isDisplay ? "표시" : "숨김",
        onCell: q => ({ style: { color: q?.isDisplay ? 'green' : "black" } })
    },
    {
        title: '순서 (드래그)',
        dataIndex: 'orderNumber',
    },
];
export const wellnessTicketGroupUpdateColumns: ColumnsType<IWellnessTicketForWellnessTicketGroupOrderNumber> = [
    {
        title: 'wellnessTicketId',
        dataIndex: 'wellnessTicketId',
    },
    {
        title: '별칭',
        dataIndex: 'name',
    },
    {
        title: '미리보기',
        width: 300,
        render: (q: any, w) => <div style={{ display: 'flex', alignItems: 'center', backgroundColor: '#FFFFFF', padding: '16px', border: '1px solid #d9d9d9', borderRadius: '4px' }}>
            <div style={{ width: '100%', marginRight: '16px' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ fontSize: '18px', lineHeight: '120%' }}>
                        {w.name}
                    </div>
                    <div>
                        {w.type === 'COUNT' ? '횟수권' : '기간권'}
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                    <div style={{ lineHeight: " 24px", color: '#676767' }}>{w.discountedPrice?.toLocaleString()}원 </div>
                    {w.discountedValue > 0 && <TextBadge text={w.discountType === 'FIXED' ? '-' + w.discountedValue?.toLocaleString() : w.discountedValue + '%'} style={{ backgroundColor: '#000000', color: '#FFFFFF', marginLeft: '4px' }} />}
                </div>
            </div>
            <CircleCheck width={20} height={20} stroke={"#AEAEAE"} fill={'white'} />
        </div>
    },
    {
        title: '표시 여부',
        dataIndex: 'isDisplay',
        render: isDisplay => isDisplay ? "표시" : "숨김",
        onCell: q => ({ style: { color: q?.isDisplay ? 'green' : "black" } })
    },
    {
        title: '순서 (드래그)',
        dataIndex: 'orderNumber',
    },
];