import { DualAxes } from '@ant-design/plots';
import dayjs from 'dayjs';
import { moneyStringFormat } from '../../../../../utils/moneyFormat';

interface IProps {
    data: Array<ISalesReportData>
}

const SalesWeeklyChart = ({ data }: IProps) => {
    // Y축의 최대 및 최소 값 설정
    const yMin = 0;
    const yMax = Math.max(...data.map(item => item.total));

    const config = {
        data,
        xField: 'date',
        legend: true,
        children: [
            {
                type: 'interval',
                yField: 'total',
                style: { maxWidth: 80 },
                label: {
                    position: 'inside',
                    render: (text: string) => moneyStringFormat(text) + '원'
                },
                interaction: {
                    // elementHighlight: true,
                    elementHighlight: { background: true },
                },
            },
            {
                type: 'line',
                yField: 'APP',
                shapeField: 'smooth',
                style: { lineWidth: 2 },
                axis: { y: false },
                scale: {
                    y: {
                        domain: [yMin, yMax]
                    }
                },
                slider: {
                    y: { labelFormatter: '~s' },
                },
                interaction: {
                    tooltip: {
                        crosshairs: false,
                        marker: false,
                    },
                },

            },
            {
                type: 'line',
                yField: 'DOSAN',
                shapeField: 'smooth',
                style: { lineWidth: 2 },
                axis: { y: false },
                scale: {
                    y: {
                        domain: [yMin, yMax]
                    }
                },
                interaction: {
                    tooltip: {
                        crosshairs: false,
                        marker: false,
                    },
                },
            },
            {
                type: 'line',
                yField: 'SEOLLEUNG',
                shapeField: 'smooth',
                style: { lineWidth: 2 },
                axis: { y: false },
                scale: {
                    y: {
                        domain: [yMin, yMax]
                    }
                },
                interaction: {
                    tooltip: {
                        crosshairs: false,
                        marker: false,
                    },
                },
            },
            {
                type: 'line',
                yField: 'GANGNAM',
                shapeField: 'smooth',
                style: { lineWidth: 2 },
                axis: { y: false },
                scale: {
                    y: {
                        domain: [yMin, yMax]
                    }
                },
                interaction: {
                    tooltip: {
                        crosshairs: false,
                        marker: false,
                    },
                },
            },
            {
                type: 'line',
                yField: 'ETC',
                shapeField: 'smooth',
                style: { lineWidth: 2 },
                axis: { y: false },
                scale: {
                    y: {
                        domain: [yMin, yMax]
                    }
                },
                interaction: {
                    tooltip: {
                        crosshairs: false,
                        marker: false,
                    },
                },
            },
        ],
    };
    return <DualAxes {...config} />;
}

export default SalesWeeklyChart