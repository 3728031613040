import { Table } from "antd"
import { TableProps } from "antd/lib";

interface IProps {
    data?: any[]
}

interface DataType {
    key: string;
    monthPeriod: string;
    RESERVATION: number;
    CHECK_IN: number;
    ABSENT: number;
    ABSENTRatio: string;
    CHECK_IN_RESERVATION_CANCELED: number;
}

const columns: TableProps<DataType>['columns'] = [
    { title: '월', dataIndex: 'monthPeriod', key: 'monthPeriod', },
    { title: '예약', dataIndex: 'RESERVATION', key: 'RESERVATION', render: q => q?.toLocaleString() },
    { title: '출석', dataIndex: 'CHECK_IN', key: 'CHECK_IN', render: q => q?.toLocaleString() },
    {
        title: '결석',
        children: [
            { title: '결석', key: 'ABSENT', dataIndex: 'ABSENT', },
            { title: '결석률', key: 'ABSENTRatio', dataIndex: 'ABSENTRatio', },
        ]
    },
    {
        title: '예약 취소',
        children: [
            { title: '취소', key: 'CHECK_IN_RESERVATION_CANCELED', dataIndex: 'CHECK_IN_RESERVATION_CANCELED', },
            { title: '예약 취소율', key: '', dataIndex: '', render: (q, record) => `${Math.round(record.CHECK_IN_RESERVATION_CANCELED / record.RESERVATION * 100).toLocaleString()}%` },
        ]
    }
];

const ReservationReportMonthlyTable = ({ data }: IProps) => {
    return <>
        <Table size="small" columns={columns} dataSource={data} bordered />
    </>
}
export default ReservationReportMonthlyTable