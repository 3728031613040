import { lazy } from 'react';

import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';

import ReportMemberDaily from '../pages/report/member/daily';
import ReportMemberTime from '../pages/report/member/time';
import ReportSalesDaily from '../pages/report/sales/daily';
import ReportSalesWeekly from '../pages/report/sales/weekly';
import ReportSalesMonthly from '../pages/report/sales/montly';
import ReportTicketSoldRank from '../pages/report/ticket/soldRank';
import ReportTicketRetention from '../pages/report/ticket/retention';
import ReportTicketSalesConversion from '../pages/report/ticket/salesConversion';
import ReportReservationCenter from '../pages/report/reservation/center';
import ReportReservationDaily from '../pages/report/reservation/daily';
import ReportReservationWeekly from '../pages/report/reservation/weekly';
import ReportReservationMonthly from '../pages/report/reservation/monthly';
import ReportReservationTicket from '../pages/report/reservation/ticket';
import WellnessTicketCreate from '../pages/wellnessTicket/create';
import WellnessTicketDetail from '../pages/wellnessTicket/detail';
import WellnessTicketUpdate from '../pages/wellnessTicket/update';
import WellnessTicket from '../pages/wellnessTicket';
import WellnessTicketGroup from '../pages/wellnessTicketGroup';
import { WellnessTicketGroupCreate } from '../pages/wellnessTicketGroup/create';
import { WellnessTicketGroupUpdate } from '../pages/wellnessTicketGroup/update';

const Member = Loadable(lazy(() => import('../pages/member')));
const MemberDetail = Loadable(lazy(() => import('../pages/member/detail')));
const MemberCreate = Loadable(lazy(() => import('../pages/member/create')));

const Teacher = Loadable(lazy(() => import('../pages/teacher')));
const TeacherCreate = Loadable(lazy(() => import('../pages/teacher/create')));
const TeacherDetail = Loadable(lazy(() => import('../pages/teacher/detail')));

const SettingBasic = Loadable(lazy(() => import('../pages/setting/basic')));
const SettingDetail = Loadable(lazy(() => import('../pages/setting/detail')));
const SettingNoticePopup = Loadable(lazy(() => import('../pages/setting/noticePopup')));
const SettingNoticePopupCreate = Loadable(lazy(() => import('../pages/setting/noticePopup/create')));
const SettingNoticePopupDetail = Loadable(lazy(() => import('../pages/setting/noticePopup/detail')));
const SettingBanner = Loadable(lazy(() => import('../pages/setting/banner')));
const SettingBannerCreate = Loadable(lazy(() => import('../pages/setting/banner/create')));
const SettingBannerDetail = Loadable(lazy(() => import('../pages/setting/banner/detail')));
const SettingImageManagement = Loadable(lazy(() => import('../pages/setting/imageManagement')));
const SettingSchedule = Loadable(lazy(() => import('../pages/setting/schedule')));
const SettingEvent = Loadable(lazy(() => import('../pages/setting/event')));
const SettingEventCreate = Loadable(lazy(() => import('../pages/setting/event/create')));
const SettingEventDetail = Loadable(lazy(() => import('../pages/setting/event/detail')));

const Reservation = Loadable(lazy(() => import('../pages/reservation')));
const ReservationCreate = Loadable(lazy(() => import('../pages/reservation/create')));
const ReservationDetail = Loadable(lazy(() => import('../pages/reservation/detail')));

const Schedule = Loadable(lazy(() => import('../pages/schedule')));

const WellnessClass = Loadable(lazy(() => import('../pages/wellnessClass')));
const WellnessClassCreate = Loadable(lazy(() => import('../pages/wellnessClass/create')));
const WellnessClassDetail = Loadable(lazy(() => import('../pages/wellnessClass/detail')));
const WellnessLectureCreate = Loadable(lazy(() => import('../pages/wellnessLecture/create')));
const WellnessLectureDetail = Loadable(lazy(() => import('../pages/wellnessLecture/detail')));
const WellnessClassUpdatePage = Loadable(lazy(() => import('../pages/wellnessClass/update')));
const WellnessLectureUpdatePage = Loadable(lazy(() => import('../pages/wellnessLecture/update')));

const YogaLectureDetail = Loadable(lazy(() => import('../pages/yogaLecture/detail')));
const YogaLectureUpdate = Loadable(lazy(() => import('../pages/yogaLecture/update')));

const CheckInHistory = Loadable(lazy(() => import('../pages/checkInHistory')));

const Coupon = Loadable(lazy(() => import('../pages/coupon')));
const CouponCreate = Loadable(lazy(() => import('../pages/coupon/create')));
const CouponUpdate = Loadable(lazy(() => import('../pages/coupon/update')));
const YogaCouponUpdate = Loadable(lazy(() => import('../pages/yogaCoupon/update')));

const Notification = Loadable(lazy(() => import('../pages/notification')));
const NotificationCreate = Loadable(lazy(() => import('../pages/notification/create')));
const NotificationReservation = Loadable(lazy(() => import('../pages/notification/reservation')));
const NotificationTemp = Loadable(lazy(() => import('../pages/notification/temp')));
const NotificationPushDetail = Loadable(lazy(() => import('../pages/notification/push/detail')));
const NotificationReservcationDetail = Loadable(lazy(() => import('../pages/notification/reservation/detail')));
const NotificationTempDetail = Loadable(lazy(() => import('../pages/notification/temp/detail')));

const Company = Loadable(lazy(() => import('../pages/company')));
const CompanyDetail = Loadable(lazy(() => import('../pages/company/detail')));

const DataEscalation = Loadable(lazy(() => import('../pages/dataEscalation')));
const Revenue = Loadable(lazy(() => import('../pages/revenue')));

const YogaProgram = Loadable(lazy(() => import('../pages/yogaProgram')));
const YogaProgramDetail = Loadable(lazy(() => import('../pages/yogaProgram/detail')));
const YogaProgramCreate = Loadable(lazy(() => import('../pages/yogaProgram/create')));

const CheckInBarcode = Loadable(lazy(() => import('../pages/checkIn/bacode')));

const Week = Loadable(lazy(() => import('../pages/week')));
const WeekCreate = Loadable(lazy(() => import('../pages/week/create')));
const WeekUpdate = Loadable(lazy(() => import('../pages/week/update')));

const Item = Loadable(lazy(() => import('../pages/item')));
const ItemCreate = Loadable(lazy(() => import('../pages/item/create')));
const ItemUpdate = Loadable(lazy(() => import('../pages/item/update')));

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        { path: '/', element: <Schedule /> },
        { path: '/schedule', element: <Schedule /> },
        { path: '/reservation', element: <Reservation /> },
        { path: '/reservation/create', element: <ReservationCreate /> },
        { path: '/reservation/:id', element: <ReservationDetail /> },
        { path: '/check-in/history', element: <CheckInHistory /> },
        { path: '/wellness-class', element: <WellnessClass /> },
        { path: '/wellness-class/create', element: <WellnessClassCreate /> },
        { path: '/wellness-class/:id', element: <WellnessClassDetail /> },
        { path: '/wellness-class/update/:id', element: <WellnessClassUpdatePage /> },
        { path: '/wellness-lecture/create', element: <WellnessLectureCreate /> },
        { path: '/wellness-lecture/:id', element: <WellnessLectureDetail /> },
        { path: '/wellness-lecture/update/:id', element: <WellnessLectureUpdatePage /> },
        { path: '/yoga-lecture/:id', element: <YogaLectureDetail /> },
        { path: '/yoga-lecture/update/:id', element: <YogaLectureUpdate /> },
        { path: '/member', element: <Member /> },
        { path: '/member/:id', element: <MemberDetail /> },
        { path: '/member/create', element: <MemberCreate /> },
        { path: '/teacher', element: <Teacher /> },
        { path: '/teacher/create', element: <TeacherCreate /> },
        { path: '/teacher/:id', element: <TeacherDetail /> },
        { path: '/coupon', element: <Coupon /> },
        { path: '/coupon/create', element: <CouponCreate /> },
        { path: '/coupon/:id', element: <CouponUpdate /> },
        { path: '/yoga-coupon/:id', element: <YogaCouponUpdate /> },
        { path: '/notification', element: <Notification /> },
        { path: '/notification/create', element: <NotificationCreate /> },
        { path: '/notification/reservation', element: <NotificationReservation /> },
        { path: '/notification/temp', element: <NotificationTemp /> },
        { path: '/notification/push/:id', element: <NotificationPushDetail /> },
        { path: '/notification/reservation/:id', element: <NotificationReservcationDetail /> },
        { path: '/notification/temp/:id', element: <NotificationTempDetail /> },
        { path: '/setting/notice-popup', element: <SettingNoticePopup /> },
        { path: '/setting/notice-popup/create', element: <SettingNoticePopupCreate /> },
        { path: '/setting/notice-popup/:id', element: <SettingNoticePopupDetail /> },
        { path: '/setting/banner', element: <SettingBanner /> },
        { path: '/setting/banner/create', element: <SettingBannerCreate /> },
        { path: '/setting/banner/:id', element: <SettingBannerDetail /> },
        { path: '/setting/basic', element: <SettingBasic /> },
        { path: '/setting/detail', element: <SettingDetail /> },
        { path: '/setting/image-management', element: <SettingImageManagement /> },
        { path: '/setting/schedule', element: <SettingSchedule /> },
        { path: '/setting/event', element: <SettingEvent /> },
        { path: '/setting/event/create', element: <SettingEventCreate /> },
        { path: '/setting/event/:id', element: <SettingEventDetail /> },
        { path: '/company', element: <Company /> },
        { path: '/company/detail/:id', element: <CompanyDetail /> },
        { path: '/data-escalation', element: <DataEscalation /> },
        { path: '/revenue', element: <Revenue /> },
        { path: '/yoga-program', element: <YogaProgram /> },
        { path: '/yoga-program/:id', element: <YogaProgramDetail /> },
        { path: '/yoga-program/create', element: <YogaProgramCreate /> },
        { path: '/check-in/barcode/:companyId/:yogaCenterId', element: <CheckInBarcode /> },
        { path: '/week', element: <Week /> },
        { path: '/week/create', element: <WeekCreate /> },
        { path: '/week/:id', element: <WeekUpdate /> },
        { path: '/item', element: <Item /> },
        { path: '/item/create', element: <ItemCreate /> },
        { path: '/item/:id', element: <ItemUpdate /> },
        { path: '/report/member/daily', element: <ReportMemberDaily /> },
        { path: '/report/member/time', element: <ReportMemberTime /> },
        { path: '/report/sales/daily', element: <ReportSalesDaily /> },
        { path: '/report/sales/weekly', element: <ReportSalesWeekly /> },
        { path: '/report/sales/monthly', element: <ReportSalesMonthly /> },
        { path: '/report/ticket/sold/rank', element: <ReportTicketSoldRank /> },
        { path: '/report/ticket/retention/rank', element: <ReportTicketRetention /> },
        { path: '/report/ticket/convention', element: <ReportTicketSalesConversion /> },
        { path: '/report/reservation/center', element: <ReportReservationCenter /> },
        { path: '/report/reservation/daily', element: <ReportReservationDaily /> },
        { path: '/report/reservation/weekly', element: <ReportReservationWeekly /> },
        { path: '/report/reservation/monthly', element: <ReportReservationMonthly /> },
        { path: '/report/reservation/ticket', element: <ReportReservationTicket /> },

        { path: '/wellness-ticket', element: <WellnessTicket /> },
        { path: '/wellness-ticket/create', element: <WellnessTicketCreate /> },
        { path: '/wellness-ticket/:id', element: <WellnessTicketDetail /> },
        { path: '/wellness-ticket/update/:id', element: <WellnessTicketUpdate /> },
        { path: '/wellness-ticket-group', element: <WellnessTicketGroup /> },
        { path: '/wellness-ticket-group/create', element: <WellnessTicketGroupCreate /> },
        { path: '/wellness-ticket-group/update/:id', element: <WellnessTicketGroupUpdate /> },
    ],
};

export default MainRoutes;