import { Table } from "antd"
import { TableProps } from "antd/lib";

interface IProps {
    data?: any[]
}

interface DataType {
    key: string;
    hour: string;
    registerCount: number;
    paidCount: number;
    paidPrice: string;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: '시간',
        dataIndex: 'hour',
        key: 'hour',
    },
    {
        title: '신규가입자',
        dataIndex: 'registerCount',
        key: 'registerCount',
    },
    {
        title: '정기권 결제 건수',
        dataIndex: 'paidCount',
        key: 'paidCount',
        children: [
            {
                title: '인앱',
                dataIndex: 'tossPaidCount',
                key: 'tossPaidCount',
                render: q => q?.toLocaleString()

            },
            {
                title: '선릉',
                dataIndex: 'SEOLLEUNGPaidCount',
                key: 'SEOLLEUNGPaidCount',
                render: q => q?.toLocaleString()
            },
            {
                title: '강남',
                dataIndex: 'GANGNAMPaidCount',
                key: 'GANGNAMPaidCount',
                render: q => q?.toLocaleString()
            },
            {
                title: '도산',
                dataIndex: 'DOSANPaidCount',
                key: 'DOSANPaidCount',
                render: q => q?.toLocaleString()
            },
            {
                title: '기타',
                dataIndex: 'EtcPaidCount',
                key: 'EtcPaidCount',
                render: q => q?.toLocaleString()
            },
        ]
    },
    {
        title: '정기권 결제 금액',
        key: 'paidPrice',
        dataIndex: 'paidPrice',
        children: [
            {
                title: '인앱',
                dataIndex: 'tossPaidPrice',
                key: 'tossPaidPrice',
                render: q => q?.toLocaleString()
            },
            {
                title: '선릉',
                dataIndex: 'SEOLLEUNGPaidPrice',
                key: 'SEOLLEUNGPaidPrice',
                render: q => q?.toLocaleString(),
            },
            {
                title: '강남',
                dataIndex: 'GANGNAMPaidPrice',
                key: 'GANGNAMPaidPrice',
                render: q => q?.toLocaleString(),
            },
            {
                title: '도산',
                dataIndex: 'DOSANPaidPrice',
                key: 'DOSANPaidPrice',
                render: q => q?.toLocaleString(),
            },
            {
                title: '기타',
                dataIndex: 'EtcPaidPrice',
                key: 'EtcPaidPrice',
                render: q => q?.toLocaleString(),
            },
        ]
    },
    {
        title: '정기권 결제 금액 비율',
        key: 'paidPriceRatio',
        dataIndex: 'paidPriceRatio',
    }
];

const defalutData: DataType[] = [
    { "key": "1", "hour": "00:00 ~ 01:00", "registerCount": 32, "paidCount": 9, "paidPrice": "12930원" },
    { "key": "2", "hour": "01:00 ~ 02:00", "registerCount": 20, "paidCount": 7, "paidPrice": "48325원" },
    { "key": "3", "hour": "02:00 ~ 03:00", "registerCount": 65, "paidCount": 8, "paidPrice": "52990원" },
    { "key": "4", "hour": "03:00 ~ 04:00", "registerCount": 50, "paidCount": 1, "paidPrice": "14053원" },
    { "key": "5", "hour": "04:00 ~ 05:00", "registerCount": 56, "paidCount": 0, "paidPrice": "38565원" },
    { "key": "6", "hour": "05:00 ~ 06:00", "registerCount": 45, "paidCount": 2, "paidPrice": "42724원" },
    { "key": "7", "hour": "06:00 ~ 07:00", "registerCount": 63, "paidCount": 8, "paidPrice": "15087원" },
    { "key": "8", "hour": "07:00 ~ 08:00", "registerCount": 36, "paidCount": 6, "paidPrice": "34835원" },
    { "key": "9", "hour": "08:00 ~ 09:00", "registerCount": 61, "paidCount": 9, "paidPrice": "46947원" },
    { "key": "10", "hour": "09:00 ~ 10:00", "registerCount": 37, "paidCount": 1, "paidPrice": "15552원" },
    { "key": "11", "hour": "10:00 ~ 11:00", "registerCount": 67, "paidCount": 1, "paidPrice": "34866원" },
    { "key": "12", "hour": "11:00 ~ 12:00", "registerCount": 29, "paidCount": 4, "paidPrice": "17188원" },
    { "key": "13", "hour": "12:00 ~ 13:00", "registerCount": 38, "paidCount": 3, "paidPrice": "23833원" },
    { "key": "14", "hour": "13:00 ~ 14:00", "registerCount": 55, "paidCount": 0, "paidPrice": "38394원" },
    { "key": "15", "hour": "14:00 ~ 15:00", "registerCount": 36, "paidCount": 7, "paidPrice": "28244원" },
    { "key": "16", "hour": "15:00 ~ 16:00", "registerCount": 51, "paidCount": 3, "paidPrice": "15637원" },
    { "key": "17", "hour": "16:00 ~ 17:00", "registerCount": 67, "paidCount": 4, "paidPrice": "27573원" },
    { "key": "18", "hour": "17:00 ~ 18:00", "registerCount": 46, "paidCount": 9, "paidPrice": "33117원" },
    { "key": "19", "hour": "18:00 ~ 19:00", "registerCount": 54, "paidCount": 8, "paidPrice": "38418원" },
    { "key": "20", "hour": "19:00 ~ 20:00", "registerCount": 46, "paidCount": 8, "paidPrice": "41837원" },
    { "key": "21", "hour": "20:00 ~ 21:00", "registerCount": 66, "paidCount": 5, "paidPrice": "43295원" },
    { "key": "22", "hour": "21:00 ~ 22:00", "registerCount": 33, "paidCount": 2, "paidPrice": "17864원" },
    { "key": "23", "hour": "22:00 ~ 23:00", "registerCount": 57, "paidCount": 8, "paidPrice": "57374원" },
    { "key": "24", "hour": "23:00 ~ 00:00", "registerCount": 68, "paidCount": 1, "paidPrice": "32478원" }
];

const MemberReportTimeTable = ({ data = defalutData }: IProps) => {
    return <>
        <Table
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
        />
    </>
}
export default MemberReportTimeTable