import { useEffect, useState } from "react";
import type { ColumnsType } from 'antd/es/table';
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, Col, DatePicker, Divider, Flex, Form, Input, InputNumber, Modal, Row, Switch, Table } from 'antd';
import dayjs from 'dayjs';
import { getDetailDataForWellnessTicketByWellnessTicketId } from "../../../service/wellnessTicket";
import { issuedWellnessTicketWithMemberInfoColumns, wellnesTicketTypeToKr } from "../model";
import { createAddIssuedWellnessTicketDateByAddIssuedWellnessTicketDateCreateAdminRequestAndToken, getAllAddIssuedWellnessTicketDateByWellnessTicketId } from "../../../service/addWellnessTicketDate";
import { TableProps } from "antd/lib";
import IssuedWellnessTicketUpdate from "./ui/update/issuedWellnessTicketUpdate";


const addIssuedWellnessTicketDateColumn: ColumnsType<IAddIssuedWellnessTicketDateBasicAdminRequest> = [
    {
        title: "연장 이유", dataIndex: "reason"
    },
    {
        title: "기준일", dataIndex: "standardDate",
    },
    {
        title: "연장일", dataIndex: "addActiveDate"
    },
    {
        title: "연장한 관리자 번호", dataIndex: "memberMobile"
    },
    {
        title: "향후 발행 적용", dataIndex: "isApplyAfter",
        render: q => q ? '적용' : '미적용'
    },
]


const WellnessTicketDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [isDisabled, setIsDeleted] = useState(true);
    const [modalOpenStatus, setModalOpenStatus] = useState<boolean>(false);
    const [wellnessTicketLoading, setWellnessTicketLoading] = useState<boolean>(false);
    const [addIssuedWellnessTicketDateList, setAddIssuedWellnessTicketDateList] = useState<IAddIssuedWellnessTicketDateBasicAdminResponse[]>();
    const [addIssuedWellnessTicketDateLoading, setAddIssuedWellnessTicketDateLoading] = useState<boolean>(false);
    const [wellnessTicket, setWellnessTicket] = useState<IWellnessTicketDetailAdminResponse>();
    const [selectedIssuedWellnessTicketList, setSelectedIssuedWellnessTicketList] = useState<IissuedWellnessTicketWithMemberInfoAdminResponse[]>([])
    const [searchNameAndMobild, setSearchNameAndMobild] = useState<string>('');
    useEffect(() => {
        requestDetailDataForWellnessTicketByWellnessTicketId()
    }, [id])

    const requestDetailDataForWellnessTicketByWellnessTicketId = () => {
        if (!id) return;
        setWellnessTicketLoading(true)

        getDetailDataForWellnessTicketByWellnessTicketId(id)
            .then(res => { setWellnessTicket(res.data); })
            .catch(err => console.error('error', err))
            .finally(() => setWellnessTicketLoading(false))
    }

    useEffect(() => {
        if (!id || !modalOpenStatus) return;
        setAddIssuedWellnessTicketDateLoading(true)
        getAllAddIssuedWellnessTicketDateByWellnessTicketId(id)
            .then(res => setAddIssuedWellnessTicketDateList(res.data))
            .catch(err => console.error('error', err))
            .finally(() => setAddIssuedWellnessTicketDateLoading(false))
    }, [id, modalOpenStatus])

    const onFinish = (values: IAddIssuedWellnessTicketDateBasicAdminRequest) => {
        if (!id) return;
        setAddIssuedWellnessTicketDateLoading(true)
        createAddIssuedWellnessTicketDateByAddIssuedWellnessTicketDateCreateAdminRequestAndToken({ ...values, wellnessTicketId: id })
            .then(res => console.log(res.data))
            .catch(err => console.error('error', err))
            .finally(() => { setAddIssuedWellnessTicketDateLoading(false); setModalOpenStatus(false) })
    };

    const rowSelection: TableProps<IissuedWellnessTicketWithMemberInfoAdminResponse>['rowSelection'] = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: IissuedWellnessTicketWithMemberInfoAdminResponse[]) => {
            setSelectedIssuedWellnessTicketList(selectedRows);
        },
        // getCheckboxProps: (record: IissuedWellnessTicketWithMemberInfoAdminResponse) => ({
        //   disabled: record.name === 'Disabled User', // Column configuration not to be checked
        //   name: record.name,
        // }),
    };

    return <>
        {wellnessTicket &&
            <div style={{ width: '310px', display: "flex", flexDirection: "column", background: `linear-gradient(135deg, ${wellnessTicket.startColor}, ${wellnessTicket.endColor})`, padding: '20px', borderRadius: '8px', color: wellnessTicket.textColor }}>
                <Flex style={{ justifyContent: 'space-between' }}>
                    <div style={{ fontSize: 22, lineHeight: '26px' }}>{wellnessTicket.name}</div>
                    <div style={{ width: 51, backgroundColor: `${wellnessTicket.textColor}66`, fontSize: 12, borderRadius: 4, padding: '4px 8px', color: wellnessTicket.textColor }}>
                        D-{wellnessTicket.usableDate}
                    </div>
                </Flex>
                <div style={{ marginTop: '80px' }}>
                    {wellnessTicket.price === wellnessTicket.discountedPrice
                        ? <div style={{ fontSize: "20px", fontWeight: 700 }}>{wellnessTicket.price?.toLocaleString()}원</div>
                        : <div style={{ display: 'flex', alignItems: 'baseline' }}>
                            <div style={{ fontSize: "20px", fontWeight: 700 }}>{wellnessTicket.discountedPrice?.toLocaleString()}원</div>
                            <div style={{ marginLeft: '10px', textDecoration: 'line-through', fontSize: '16px' }}>{wellnessTicket.price?.toLocaleString()}</div>
                        </div>}
                </div>
            </div>}

        <div style={{ marginTop: '10px' }}>
            <Button onClick={() => navigate(`/wellness-ticket/update/${id}`)}>수정</Button>

        </div>
        <br /><br />
        <h3>
            발급된 정기권
        </h3>
        <Button style={{ marginLeft: '10px' }} onClick={() => setModalOpenStatus(true)}>일괄 연장</Button>
        <div style={{ textAlign: 'right', marginBottom: '5px' }}><Switch defaultChecked onChange={q => setIsDeleted(q)} checkedChildren="유효한 정기권" unCheckedChildren="유효한 정기권" /></div>
        <Row gutter={24}>
            <Col xs={24} xl={selectedIssuedWellnessTicketList.length > 0 ? 14 : 24}>
                <Input style={{ width: "470px", marginBottom: 20 }} value={searchNameAndMobild} onChange={(e) => setSearchNameAndMobild(e.target.value)} placeholder="이름 또는 전화번호 검색" />
                <Table
                    rowSelection={{ type: 'checkbox', ...rowSelection }}
                    columns={issuedWellnessTicketWithMemberInfoColumns}
                    loading={wellnessTicketLoading}
                    dataSource={wellnessTicket?.issuedWellnessTicketWithMemberInfoList
                        .filter(i => isDisabled ? !i.isDelete : true)
                        .filter((e) => searchNameAndMobild === '' ? true : e.memberName.includes(searchNameAndMobild) || e.memberMobile.includes(searchNameAndMobild))
                        .map(i => ({ ...i, key: i.id }))
                        .sort((a, b) => dayjs(b.startDate).diff(dayjs(a.startDate)))
                    }
                    size="small" />
            </Col>
            {selectedIssuedWellnessTicketList.length > 0 && <>
                <Col xs={24} xl={10}>
                    <IssuedWellnessTicketUpdate selectedIssuedWellnessTicketList={selectedIssuedWellnessTicketList} refresh={requestDetailDataForWellnessTicketByWellnessTicketId} />
                </Col>
            </>}

        </Row>
        <Modal title="일괄 연장" open={modalOpenStatus} onOk={() => setModalOpenStatus(false)} onCancel={() => setModalOpenStatus(false)} footer={false} width={800}>
            <Form
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ standardDate: dayjs(), addActiveDate: 1, isApplyAfter: false }}
                onFinish={onFinish}
            >
                <Form.Item
                    label="기준일"
                    name="standardDate"
                    rules={[{ required: true, message: '필수 입력값입니다.' }]}
                >
                    <DatePicker />
                </Form.Item>
                <Form.Item
                    label="연장일"
                    name="addActiveDate"
                    rules={[{ required: true, message: '필수 입력값입니다.' }]}
                >
                    <InputNumber />
                </Form.Item>
                <Form.Item
                    label="연장 이유"
                    name="reason"
                    rules={[{ required: true, message: '필수 입력값입니다.' }]}
                >
                    <Input.TextArea />
                </Form.Item>
                <Form.Item
                    label="향후 발행 적용"
                    name="isApplyAfter"
                    valuePropName='checked'
                    tooltip="지금부터 기준일까지 발행되는 정기권에도 소급 적용됩니다."
                >
                    <Checkbox />
                </Form.Item>
                <Form.Item label colon={false}>
                    <Button type="primary" htmlType="submit" loading={addIssuedWellnessTicketDateLoading}>
                        연장
                    </Button>
                </Form.Item>
            </Form>
            <Divider />
            <Table
                columns={addIssuedWellnessTicketDateColumn}
                dataSource={addIssuedWellnessTicketDateList}
                loading={addIssuedWellnessTicketDateLoading}
                size="small" />
        </Modal>
    </>
}
export default WellnessTicketDetail;