import { AxiosResponse } from 'axios';
import api from './Api'

export const createWellnessTicketByWellnessTicketCreateAdminRequest = (wellnessTicketCreateAdminRequest: IWellnessTicketCreateAdminRequest) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket/admin/v2`, wellnessTicketCreateAdminRequest);
}
export const getUpdateDataForWellnessTicketByWellnessTicketId = (wellnessTicketId: string): Promise<AxiosResponse<IUpdateDataForWellnessTicketAdminResponse, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket/update/admin/v2/${wellnessTicketId}`)
}
export const updateWellnessTicketByWellnessTicketUpdateAdminRequest = (wellnessTicketUpdateRequest: IWellnessTicketUpdateAdmiinRequest) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket/admin/v2`, wellnessTicketUpdateRequest);
}
export const getAllWellnessTicketByCompanyIdAndIsDeleteFalse = (companyId: number): Promise<AxiosResponse<IWellnessTicketForListAdminResponse[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket/is-delete-false/admin/v2/${companyId}`)
}
export const getAllWellnessTicketByCompanyId = (companyId: number): Promise<AxiosResponse<IWellnessTicketForListAdminResponse[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket/admin/v2/${companyId}`)
}
export const getDetailDataForWellnessTicketByWellnessTicketId = (wellnessTicketId: string): Promise<AxiosResponse<IWellnessTicketDetailAdminResponse, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket/detail/admin/v2/${wellnessTicketId}`)
}