import { ThunkAction } from 'redux-thunk';
import { RootState } from '..';
import { ReservationAction } from './types';
import { getAllReservationBySearchAndWellnessLectureStartDateTimeBetween } from '../../service/reservation'
import { getReservationListAsync } from './actions';

export function getReservationListThunk(searchValue: string = "", startDate: string = "", endDate: string = ""): ThunkAction<void, RootState, null, ReservationAction> {
    searchValue = searchValue ?? '';
    startDate = startDate ?? '';
    endDate = endDate ?? '';
    return async dispatch => {
        const { request, success, failure } = getReservationListAsync;
        dispatch(request());
        try {
            const reservationList = await getAllReservationBySearchAndWellnessLectureStartDateTimeBetween(searchValue, startDate, endDate);
            dispatch(success(reservationList.data));
        } catch (e: any) {
            dispatch(failure(e));
        }
    };
};