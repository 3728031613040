import { Column } from '@ant-design/plots';
interface IProps {
    value?: any[]
}
const ReservationReportWeeklyChart = ({ value }: IProps) => {
    const config = {
        data: value,
        xField: 'weekPeriod',
        yField: 'count',
        colorField: 'status',
        group: true,
        label: {
            position: 'outside',
            formatter: (text: string, datum: any, index: number, data: any) => `${datum.status}\n${datum.count}`
        },
        style: {
            inset: 5,
        },
    };
    return <Column {...config} />;
}

export default ReservationReportWeeklyChart