import { Table } from "antd"
import { TableProps } from "antd/lib";

interface IProps {
    data?: any[]
}

interface DataType {
    key: string;
    day: string;
    registerCount: number;
    paidCount: number;
    paidPrice: string;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: '요일',
        dataIndex: 'day',
        key: 'day',
    },
    {
        title: '신규가입자',
        dataIndex: 'registerCount',
        key: 'registerCount',
    },
    {
        title: '정기권 결제 건수',
        dataIndex: 'paidCount',
        key: 'paidCount',
        children: [
            {
                title: '인앱',
                dataIndex: 'tossPaidCount',
                key: 'tossPaidCount',
                render: q => q?.toLocaleString()

            },
            {
                title: '선릉',
                dataIndex: 'SEOLLEUNGPaidCount',
                key: 'SEOLLEUNGPaidCount',
                render: q => q?.toLocaleString()
            },
            {
                title: '강남',
                dataIndex: 'GANGNAMPaidCount',
                key: 'GANGNAMPaidCount',
                render: q => q?.toLocaleString()
            },
            {
                title: '도산',
                dataIndex: 'DOSANPaidCount',
                key: 'DOSANPaidCount',
                render: q => q?.toLocaleString()
            },
            {
                title: '기타',
                dataIndex: 'EtcPaidCount',
                key: 'EtcPaidCount',
                render: q => q?.toLocaleString()
            },
        ],
    },
    {
        title: '정기권 결제 금액',
        key: 'paidPrice',
        dataIndex: 'paidPrice',
        children: [
            {
                title: '인앱',
                dataIndex: 'tossPaidPrice',
                key: 'tossPaidPrice',
                render: q => q?.toLocaleString()
            },
            {
                title: '선릉',
                dataIndex: 'SEOLLEUNGPaidPrice',
                key: 'SEOLLEUNGPaidPrice',
                render: q => q?.toLocaleString(),
            },
            {
                title: '강남',
                dataIndex: 'GANGNAMPaidPrice',
                key: 'GANGNAMPaidPrice',
                render: q => q?.toLocaleString(),
            },
            {
                title: '도산',
                dataIndex: 'DOSANPaidPrice',
                key: 'DOSANPaidPrice',
                render: q => q?.toLocaleString(),
            },
            {
                title: '기타',
                dataIndex: 'EtcPaidPrice',
                key: 'EtcPaidPrice',
                render: q => q?.toLocaleString(),
            },
        ]
    },
    {
        title: '정기권 결제 금액 비율',
        key: 'paidPriceRatio',
        dataIndex: 'paidPriceRatio',
    }
];

const defalutData: DataType[] = [
    {
        key: '1',
        day: '월요일',
        registerCount: 32,
        paidCount: 5,
        paidPrice: "50,000원"
    },
    {
        key: '2',
        day: '화요일',
        registerCount: 42,
        paidCount: 5,
        paidPrice: "50,000원"
    },
    {
        key: '3',
        day: '수요일',
        registerCount: 32,
        paidCount: 5,
        paidPrice: "50,000원"
    },
    {
        key: '4',
        day: '목요일',
        registerCount: 32,
        paidCount: 5,
        paidPrice: "50,000원"
    },
    {
        key: '5',
        day: '금요일',
        registerCount: 32,
        paidCount: 5,
        paidPrice: "50,000원"
    },
    {
        key: '6',
        day: '토요일',
        registerCount: 32,
        paidCount: 5,
        paidPrice: "50,000원"
    }, {
        key: '7',
        day: '일요일',
        registerCount: 32,
        paidCount: 5,
        paidPrice: "50,000원"
    },
];

const MemberReportDailyTable = ({ data = defalutData }: IProps) => {
    return <>
        <Table size="small" columns={columns} dataSource={data} bordered />
    </>
}
export default MemberReportDailyTable