import { Button, Spin, Table } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../modules";
import SettingCompanyYogaCenter from "../../components/SettingCompanyYogaCenter";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { wellnessTicketGroupColumns } from "./model";
import { getAllWellnessTicketGroupByCompanyId } from "../../service/wellnessTicketGroup";

const WellnessTicketGroup = () => {
    const navigate = useNavigate()
    const { companyId } = useSelector((state: RootState) => state.selectedCenter);
    const { companyLoading } = useSelector((state: RootState) => state.company.companyList);
    const [wellnessTicketGroupList, setWellnessTicketGroupList] = useState<IWellnessTicketGroupListAdminResponse[]>([]);
    const [loading, setLoading] = useState<boolean>(false);


    useEffect(() => {
        if (!(companyId)) return;
        setLoading(true);
        getAllWellnessTicketGroupByCompanyId(companyId)
            .then((res) => setWellnessTicketGroupList(res.data))
            .catch((err) => console.log(err))
            .finally(() => setLoading(false));
    }, [companyId])

    return (<>
        {companyLoading ? <Spin /> : <SettingCompanyYogaCenter onlyCompany={true} />}
        {companyId && <>
            <div style={{ textAlign: "right", marginBottom: '20px' }}>
                <Button disabled={companyId === undefined} onClick={() => navigate('/wellness-ticket-group/create')}>그룹 생성</Button>
            </div>
        </>}
        <Table columns={wellnessTicketGroupColumns} dataSource={wellnessTicketGroupList?.map(q => ({ ...q, key: q.id }))} loading={loading} />
    </>)
}

export default WellnessTicketGroup;