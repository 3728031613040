import { Button, DatePicker, Space } from "antd";
import { DatePickerProps } from "antd/lib";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

const { RangePicker } = DatePicker;

const getYearMonth = (date: Dayjs) => date.year() * 12 + date.month();

const disabled7DaysDate: DatePickerProps['disabledDate'] = (current, { from, type }) => {
    if (from) {
        const minDate = from.add(-6, 'days');
        const maxDate = from.add(6, 'days');

        switch (type) {
            case 'year':
                return current.year() < minDate.year() || current.year() > maxDate.year();

            case 'month':
                return (
                    getYearMonth(current) < getYearMonth(minDate) ||
                    getYearMonth(current) > getYearMonth(maxDate)
                );
            default:
                return Math.abs(current.diff(from, 'days')) < 6;
        }
    }
    return false;
};

interface IProps {
    onClick: Function
    startDate: Dayjs
    format?: string;
}

const DateRangePicker = ({ startDate, onClick, format = "YYYY-MM-DD" }: IProps) => {
    const [targetDate, setTargetDate] = useState<[start: Dayjs | undefined, end: Dayjs | undefined]>([startDate, dayjs()]);

    return <Space>
        <RangePicker picker="date"
            disabledDate={disabled7DaysDate}
            onChange={dates => {
                if (dates && dates[0] && dates[1]) {
                    const firstDayOfSelectedStartWeek = dates[0];
                    const lastDayOfSelectedEndWeek = dates[1];

                    setTargetDate([firstDayOfSelectedStartWeek, lastDayOfSelectedEndWeek]);
                } else {
                    setTargetDate([undefined, undefined]);
                }
            }} value={targetDate} />
        <Button
            type="primary"
            onClick={() => onClick(targetDate[0]?.format(format), targetDate[1]?.format(format))}>검색</Button>
    </Space>
}

export default DateRangePicker;