import { Button, Checkbox, Col, ColorPicker, Form, Input, InputNumber, Radio, Row, Select, message } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../modules";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingCompanyYogaCenter from "../../../components/SettingCompanyYogaCenter";
import { createWellnessTicketByWellnessTicketCreateAdminRequest } from "../../../service/wellnessTicket";
import { wellnesTicketFrequencyUnitToKr } from "../model";
import { getWellnessClassDataForCreateLectureList } from "../../../service/wellnessClass";


const WellnessTicketCreate = () => {
    const navigate = useNavigate();
    const { companyId } = useSelector((state: RootState) => state.selectedCenter);
    const { companyData } = useSelector((state: RootState) => state.company.companyList);
    const [frequencyUnit, setFrequencyUnit] = useState<"YEAR" | "MONTH" | "WEEK" | "DAY" | "NOLIMIT">('NOLIMIT');
    const [type, setType] = useState<"COUNT" | "PERIOD">("COUNT");
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [selectWellnessClassListAvailable, setSelectWellnessClassListAvailable] = useState<Array<number>>([]);
    const [wellnessClassList, setWellnessClassList] = useState<Array<IWellnessClassDataForCreateLectureListResponse> | undefined>(undefined);

    useEffect(() => {
        getWellnessClassDataForCreateLectureList()
            .then(res => setWellnessClassList(res.data))
            .catch(err => console.error('err', err))
    }, [])

    const getOptions = () => {
        if (!wellnessClassList) return;
        const groupedData = wellnessClassList.reduce((acc, current) => {
            const existing = acc.find(item => item.title === current.yogaCenterName);

            const newOption = { label: <span>{current.name}</span>, value: current.id };

            if (existing) {
                existing.options.push(newOption);
            } else {
                acc.push({
                    label: <span>{current.yogaCenterName}</span>,
                    title: current.yogaCenterName,
                    options: [newOption],
                });
            }
            return acc;
        }, [] as Array<{ label: JSX.Element; title: string; options: Array<{ label: JSX.Element; value: number }> }>);

        return groupedData;
    };


    const onFinish = (values: IWellnessTicketCreateAdminRequest) => {
        if (!companyId) return;
        values.companyId = companyId
        values.startColor = (typeof values.startColor === 'string') ? values.startColor : values.startColor.toHexString();
        values.endColor = (typeof values.endColor === 'string') ? values.endColor : values.endColor.toHexString();
        values.textColor = (typeof values.textColor === 'string') ? values.textColor : values.textColor.toHexString();
        values.wellnessClassIdList = selectWellnessClassListAvailable;
        setLoading(true)
        createWellnessTicketByWellnessTicketCreateAdminRequest(values)
            .then((res) => navigate('/wellness-ticket'))
            .catch((error) => message.error('생성을 실패했습니다.'))
            .finally(() => { setLoading(false); })
    };

    const YogaCenterListRadioForm = () => {
        if (!companyId) return <Form.Item label="요가원 선택">회사를 선택해주세요.</Form.Item>
        const selectedCompany = companyData?.find((company: ICompanyData) => company.id === companyId);
        if (!selectedCompany) return <Form.Item label="요가원 선택">등록되지 않은 회사입니다.</Form.Item>
        if (selectedCompany.yogaCenterList.length === 0) return <Form.Item label="요가원 선택">등록된 요가원이 없습니다.</Form.Item>

        return <Form.Item name="yogaCenterIdList" label="요가원 선택" rules={[{ required: true, message: '필수 입력사항입니다.' }]}>
            <Select
                mode='multiple'
                options={selectedCompany.yogaCenterList.map((yogaCenter: IYogaCenter) => ({ value: yogaCenter.id, label: yogaCenter.name }))}
                onChange={(value: number[]) => {
                    const wellnessClassListInYogaCenter = wellnessClassList?.filter((wellnessClass: IWellnessClassDataForCreateLectureListResponse) => value.includes(wellnessClass.yogaCenterId))
                        .map((wellnessClass: IWellnessClassDataForCreateLectureListResponse) => wellnessClass.id)

                    if (wellnessClassListInYogaCenter) {
                        setSelectWellnessClassListAvailable(wellnessClassListInYogaCenter)
                    }
                }} />
        </Form.Item >
    }

    return <>
        {!companyData && <SettingCompanyYogaCenter onlyCompany={true} />}
        <Form
            form={form}
            labelCol={{ span: 3 }}
            labelAlign="left"
            wrapperCol={{ span: 16 }}
            colon={false}
            onFinish={onFinish}
            requiredMark={(label, info) => <div>{label}{info.required && <span style={{ color: 'red' }}>*</span>}</div>}
        >
            <Form.Item label="종류" name="type" rules={[{ required: true, message: '필수 선택값입니다.' }]} initialValue={"COUNT"}>
                <Radio.Group value={type} onChange={q => setType(q.target.value)}>
                    <Radio value="COUNT">횟수권</Radio>
                    <Radio value="PERIOD">기간권</Radio>
                </Radio.Group>
            </Form.Item>
            <YogaCenterListRadioForm />
            <Form.Item label="이름" name="name" rules={[{ required: true, message: '필수 입력값입니다.' }]}>
                <Input placeholder="정기권 이름을 입력해 주세요" />
            </Form.Item>
            <Form.Item label="이용 제한">
                <Form.Item name="frequencyUnit" initialValue={"NOLIMIT"}>
                    <Radio.Group onChange={e => setFrequencyUnit(e.target.value)}>
                        <Radio value="YEAR">연</Radio>
                        <Radio value="MONTH">월</Radio>
                        <Radio value="WEEK">주</Radio>
                        <Radio value="DAY">일</Radio>
                        <Radio value={"NOLIMIT"}>없음</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item name="frequencyUsableCnt">
                    <InputNumber style={{ width: '100%' }} controls={false} min={0} prefix={<span style={{ marginRight: '15px' }}>{wellnesTicketFrequencyUnitToKr(frequencyUnit)}</span>} suffix="회 수업 예약 가능" disabled={frequencyUnit === 'NOLIMIT'} />
                </Form.Item>
            </Form.Item>
            {type === "COUNT" &&
                <Form.Item label="총 사용 가능 횟수" name="totalUsableCnt" rules={[{ required: type === 'COUNT' && true, message: '숫자만 입력해 주세요.' }]}>
                    <InputNumber style={{ width: '100%' }} controls={false} min={0} placeholder="숫자만 입력해 주세요" />
                </Form.Item>}
            <Form.Item label="사용 기한(일)" name="usableDate" rules={[{ required: true, message: '숫자만 입력해 주세요.' }]}>
                <InputNumber style={{ width: '100%' }} controls={false} min={0} placeholder="숫자만 입력해 주세요" />
            </Form.Item>
            <Form.Item label="판매 가격" name="price" rules={[{ required: true, message: '숫자만 입력해 주세요.' }]}>
                <InputNumber style={{ width: '100%' }} controls={false} min={0} max={2147483647} placeholder="숫자만 입력해 주세요" />
            </Form.Item>
            <Form.Item label="할인 방법" name="discountType" rules={[{ required: true, message: '필수로 선택해 주세요.' }]}>
                <Radio.Group>
                    <Radio value="PERCENT">비율</Radio>
                    <Radio value="FIXED">금액</Radio>
                </Radio.Group>
            </Form.Item>
            <Form.Item label="할인 정도" name="discountedValue" rules={[{ required: true, message: '숫자만 입력해 주세요.' }]} initialValue={0}>
                <InputNumber style={{ width: '100%' }} controls={false} min={0} placeholder="숫자만 입력해 주세요" />
            </Form.Item>
            <Form.Item label="할인된 가격" name="discountedPrice" rules={[{ required: true, message: '숫자만 입력해 주세요.' }]}>
                <InputNumber style={{ width: '100%' }} controls={false} min={0} max={2147483647} placeholder="숫자만 입력해 주세요" />
            </Form.Item>

            <Row style={{ marginBottom: 30 }}>
                <Col xs={24} sm={3} style={{ textAlign: 'left', paddingRight: 5 }}>예약 가능한 수업 : </Col>
                <Col xs={24} sm={16}>
                    <Select
                        mode='multiple'
                        allowClear
                        showSearch
                        optionFilterProp="label"
                        onChange={(e) => setSelectWellnessClassListAvailable(e)}
                        value={selectWellnessClassListAvailable}
                        options={getOptions()}
                        style={{ width: '100%' }}
                    />
                </Col>
            </Row>

            <Form.Item label="정기권 색상" style={{ marginBottom: 0 }} colon={false}>
                <Form.Item name="startColor" label="시작 배경색" initialValue={'#1677FF'} style={{ display: 'inline-block', width: '120px' }}>
                    <ColorPicker format={'hex'} />
                </Form.Item>
                <Form.Item name="endColor" label="종료 배경색" initialValue={'#1677FF'} style={{ display: 'inline-block', width: '120px' }}>
                    <ColorPicker format={'hex'} />
                </Form.Item>
                <Form.Item name="textColor" label="텍스트 색상" initialValue={'#1677FF'} style={{ display: 'inline-block', width: '120px' }}>
                    <ColorPicker format={'hex'} />
                </Form.Item>
            </Form.Item>
            <Form.Item label="정기권 표시" name="isDisplay" valuePropName="checked" initialValue={false} colon={false}>
                <Checkbox>정기권 선택 페이지에 표시됩니다</Checkbox>
            </Form.Item>
            <Form.Item name="isDelete" initialValue={false} valuePropName="checked" style={{ display: 'none' }}>
                <Checkbox />
            </Form.Item>
            <Form.Item label colon={false} style={{ width: '100%', height: '60px', marginBottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                <div style={{ display: 'flex', gap: '25px' }}>
                    <Button type="primary" htmlType="submit" loading={loading}>생성</Button>
                    <Button type="default" htmlType="button" onClick={() => navigate(-1)}>취소</Button>
                </div>
            </Form.Item>
        </Form>
    </>
}

export default WellnessTicketCreate;