import { Column } from '@ant-design/plots';
interface IProps {
    data: Array<ITicketConversionData>
}

const TICKETNAME = (id: number): string => {
    return ticketNames[id] || '알수 없음';
};
const ticketNames: { [key: number]: string } = {
    0: '미전환',
    18: 'ALL 1회',
    54: 'ALL 4회',
    23: 'ALL 10회',
    24: 'ALL 20회',
    25: 'ALL 40회',
    26: 'ALL 60회',
    5: '강남 1회',
    6: '강남 10회',
    7: '강남 20회',
    8: '강남 40회',
    19: '강남 60회',
    55: '강남 TTC',
    59: '선릉・도산 1회',
    60: '선릉・도산 4회',
    61: '선릉・도산 10회',
    62: '선릉・도산 20회',
};

const ticketIdList = [18, 54, 23, 24, 25, 26, 5, 6, 7, 8, 19, 55, 59, 60, 61, 62];

const TicketSalesConversionReportChart = ({ data }: IProps) => {
    const dataValue: any = [];
    const dataConvert = ticketIdList.forEach((ticketId: number) => {
        const baseTicketIdData = data.find((e: ITicketConversionData) => e.id === ticketId);
        baseTicketIdData?.conversion.forEach((i: any) => {
            if (i.cnt > 0)
                dataValue.push({
                    targetTicket: TICKETNAME(ticketId),
                    conversionTicketName: TICKETNAME(i.id),
                    count: i.cnt
                })
        })
    });

    const config = {
        data: {
            value: dataValue,
        },
        xField: 'targetTicket',
        yField: 'count',
        colorField: 'conversionTicketName',
        stack: true,
        sort: {
            reverse: true,
            by: 'y',
        },
        axis: {
            y: { labelFormatter: '~s' },
            x: {
                labelSpacing: 4,
                style: {
                },
            },
        },
    };
    return <Column {...config} />;
}

export default TicketSalesConversionReportChart