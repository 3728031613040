import { useEffect, useState } from "react";
import { createWellnessTicketGroupByWellnessTicketGroupBasicAdminRequest } from "../../../service/wellnessTicketGroup";
import { Button, Checkbox, Form, Input, InputNumber, Select, Spin } from "antd";
import { useSelector } from "react-redux";
import { RootState } from "../../../modules";
import SettingCompanyYogaCenter from "../../../components/SettingCompanyYogaCenter";
import DragAbleTable from "../../../components/DataTable/DragAble";
import { useNavigate } from "react-router-dom";
import { wellnessTicketGroupCreateColumns } from "../model";
import { getAllWellnessTicketByCompanyId } from "../../../service/wellnessTicket";

const WellnessTicketGroupCreate = () => {
    const navigate = useNavigate();
    const { companyId } = useSelector((state: RootState) => state.selectedCenter);
    const { companyLoading } = useSelector((state: RootState) => state.company.companyList);
    const [getLoading, setGetLoading] = useState<boolean>(false);
    const [createLoading, setCreateLoading] = useState<boolean>(false);
    const [wellnessTicketList, setWellnessTicketList] = useState<IWellnessTicketForListAdminResponse[]>([]);
    const [dataSource, setDataSource] = useState<any[]>([]);

    useEffect(() => {
        if (!companyId) return;
        setGetLoading(true);
        getAllWellnessTicketByCompanyId(companyId)
            .then(res => setWellnessTicketList(res.data.map(q => ({ ...q, key: q.id }))))
            .catch(err => console.log(err))
            .finally(() => setGetLoading(false))
    }, [companyId])

    const handleTicketSelection = (wellnessTicketIdList: number[]) => {
        const initialOrderNumbers = wellnessTicketList.filter(q => wellnessTicketIdList.includes(q.id))
        setDataSource(initialOrderNumbers.map((q, idx) => ({ ...q, orderNumber: idx })))
    };
    const onFinish = (values: any) => {
        if (!companyId) return;
        values.companyId = companyId;
        values.wellnessTicketGroupOrderNumberList = dataSource.map(q => ({ wellnessTicketId: q.wellnessTicketId, orderNumber: q.orderNumber }))
        setCreateLoading(true);
        createWellnessTicketGroupByWellnessTicketGroupBasicAdminRequest(values)
            .then(res => console.log(res.data))
            .catch(err => console.log(err))
            .finally(() => { setCreateLoading(false); navigate('/wellness-ticket-group') })
    };
    return <>
        {companyLoading ? <Spin /> : <SettingCompanyYogaCenter onlyCompany={true} />}
        <Form
            onFinish={onFinish}>
            <Form.Item
                label="그룹 이름"
                name="title"
                rules={[{ required: true, message: '필수 입력값입니다.' }]}
            >
                <Input placeholder="그룹 이름을 입력해주세요." />
            </Form.Item>
            <Form.Item
                label="그룹 설명"
                name="description"
                rules={[{ required: true, message: '필수 입력값입니다.' }]}
            >
                <Input.TextArea placeholder="그룹 설명을 입력해주세요." />
            </Form.Item>
            <Form.Item
                label="그룹 순서"
                name="idx"
                rules={[{ required: true, message: '필수 입력값입니다.' }]}
            >
                <InputNumber placeholder="오름차순으로 표시 됩니다." />
            </Form.Item>
            <Form.Item label="포함 정기권">
                <Select
                    mode="multiple"
                    placeholder="Select tickets"
                    onChange={handleTicketSelection}
                    loading={getLoading}
                    options={wellnessTicketList.map(q => ({ value: q.id, label: q.name, }))}
                />
            </Form.Item>
            <DragAbleTable columns={wellnessTicketGroupCreateColumns} dataSource={dataSource} setDataSource={setDataSource} targetName='orderNumber' tableOption={{ scroll: { y: 1000 }, pagination: false }} />
            <Form.Item
                style={{ marginTop: '15px' }}
                label="표시 여부"
                name="isDisplay"
                valuePropName="checked"
                initialValue={true}
            >
                <Checkbox />
            </Form.Item>
            <Form.Item label colon={false} style={{ width: '100%', height: '60px', marginBottom: '0', display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
                <div style={{ display: 'flex', gap: '25px' }}>
                    <Button type="primary" htmlType="submit" loading={createLoading}>생성</Button>
                    <Button type="default" htmlType="button" onClick={() => navigate(-1)}>취소</Button>
                </div>
            </Form.Item>
        </Form>
    </>
}

export { WellnessTicketGroupCreate };