import { Column } from '@ant-design/plots';

interface IProps {
    value?: any[]
}

const MemberReportTimeChart = ({ value }: IProps) => {
    const config = {
        data: {
            value: value ?? [{ "name": "신규 가입자", "hour": "0시", "count": 59 },
            { "name": "결제 건수", "hour": "0시", "count": 45 },
            { "name": "신규 가입자", "hour": "1시", "count": 23.9 },
            { "name": "결제 건수", "hour": "1시", "count": 29 },
            { "name": "신규 가입자", "hour": "2시", "count": 99.0 },
            { "name": "결제 건수", "hour": "2시", "count": 9 },
            { "name": "신규 가입자", "hour": "3시", "count": 42.3 },
            { "name": "결제 건수", "hour": "3시", "count": 32 },
            { "name": "신규 가입자", "hour": "4시", "count": 20.7 },
            { "name": "결제 건수", "hour": "4시", "count": 34 },
            { "name": "신규 가입자", "hour": "5시", "count": 38.2 },
            { "name": "결제 건수", "hour": "5시", "count": 48 },
            { "name": "신규 가입자", "hour": "6시", "count": 66.4 },
            { "name": "결제 건수", "hour": "6시", "count": 45 },
            { "name": "신규 가입자", "hour": "7시", "count": 71.9 },
            { "name": "결제 건수", "hour": "7시", "count": 25 },
            { "name": "신규 가입자", "hour": "8시", "count": 67.4 },
            { "name": "결제 건수", "hour": "8시", "count": 28 },
            { "name": "신규 가입자", "hour": "9시", "count": 77.7 },
            { "name": "결제 건수", "hour": "9시", "count": 1 },
            { "name": "신규 가입자", "hour": "10시", "count": 39.7 },
            { "name": "결제 건수", "hour": "10시", "count": 1 },
            { "name": "신규 가입자", "hour": "11시", "count": 81.4 },
            { "name": "결제 건수", "hour": "11시", "count": 20 },
            { "name": "신규 가입자", "hour": "12시", "count": 52.6 },
            { "name": "결제 건수", "hour": "12시", "count": 26 },
            { "name": "신규 가입자", "hour": "13시", "count": 61.3 },
            { "name": "결제 건수", "hour": "13시", "count": 1 },
            { "name": "신규 가입자", "hour": "14시", "count": 78.4 },
            { "name": "결제 건수", "hour": "14시", "count": 45 },
            { "name": "신규 가입자", "hour": "15시", "count": 44.8 },
            { "name": "결제 건수", "hour": "15시", "count": 30 },
            { "name": "신규 가입자", "hour": "16시", "count": 15.7 },
            { "name": "결제 건수", "hour": "16시", "count": 36 },
            { "name": "신규 가입자", "hour": "17시", "count": 42.6 },
            { "name": "결제 건수", "hour": "17시", "count": 43 },
            { "name": "신규 가입자", "hour": "18시", "count": 36.3 },
            { "name": "결제 건수", "hour": "18시", "count": 32 },
            { "name": "신규 가입자", "hour": "19시", "count": 8.6 },
            { "name": "결제 건수", "hour": "19시", "count": 0 },
            { "name": "신규 가입자", "hour": "20시", "count": 49.5 },
            { "name": "결제 건수", "hour": "20시", "count": 25 },
            { "name": "신규 가입자", "hour": "21시", "count": 49.0 },
            { "name": "결제 건수", "hour": "21시", "count": 40 },
            { "name": "신규 가입자", "hour": "22시", "count": 53.8 },
            { "name": "결제 건수", "hour": "22시", "count": 38 },
            { "name": "신규 가입자", "hour": "23시", "count": 30.6 },
            { "name": "결제 건수", "hour": "23시", "count": 38 }]
        },
        xField: 'hour',
        yField: 'count',
        colorField: 'name',
        group: true,
        style: {
            inset: 5,
        },
    };
    return <Column {...config} />;
}

export default MemberReportTimeChart