import dayjs from "dayjs";
import DateRangePicker from "../../ui/dateRangePicker";
import SalesMonthlyChart from "./ui/chart";
import SalesReportTableMonthly from "./ui/table";
import { getReportSalesMonthly } from "../../../../service/report/sales";
import { useEffect, useState } from "react";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";

const ReportSalesMonthly = () => {
    const [salesDailyChartData, setSalesDailyChartData] = useState<Array<ISalesReportData>>([])
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);

    useEffect(() => {
        requestReportSalesMonthly(dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    }, [])

    const requestReportSalesMonthly = (startDate: string, endDate: string) => {
        getReportSalesMonthly(startDate, endDate)
            .then((res) => setSalesDailyChartData(res.data))
            .catch(error => console.error('error', error))
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        <DateRangePicker
            startDate={dayjs().subtract(6, 'month')}
            onClick={(startDate: string, endDate: string) => requestReportSalesMonthly(startDate, endDate)} />
        <br /><br />
        {salesDailyChartData.length > 0 && <>
            <SalesMonthlyChart data={salesDailyChartData} />
            <SalesReportTableMonthly data={salesDailyChartData} />
        </>}
    </>
}

export default ReportSalesMonthly