import dayjs from "dayjs";
import DateRangePicker from "../../ui/dateRangePicker";
import TicketSoldRankReportChart from "./ui/chart";
import TicketSoldRankReportTable from "./ui/table";
import { useEffect, useState } from "react";
import { getReportTicketSalesRank } from "../../../../service/report/ticket";
import { useSelector } from "react-redux";
import { RootState } from "../../../../modules";
import SuperAdminPassword from "../../../../components/SuperAdminPassword";

const ReportTicketSoldRank = () => {
    const [ticketSalesRankData, setTicketSalesRankData] = useState<Array<ITicketSalesRankData>>([])
    const { isSuperAdmin } = useSelector((state: RootState) => state.superAdmin);

    useEffect(() => {
        requestReportTicketSalesRank(dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD'))
    }, [])

    const requestReportTicketSalesRank = (startDate: string, endDate: string) => {
        getReportTicketSalesRank(startDate, endDate)
            .then((res) => setTicketSalesRankData(res.data))
            .catch(error => console.error('error', error))
    }

    if (!isSuperAdmin) {
        return <SuperAdminPassword />
    }

    return <>
        <DateRangePicker
            startDate={dayjs().subtract(6, 'month')}
            onClick={(startDate: string, endDate: string) => requestReportTicketSalesRank(startDate, endDate)} />
        <br /><br />
        {ticketSalesRankData.length > 0 && <>
            <TicketSoldRankReportChart data={ticketSalesRankData} />
            <TicketSoldRankReportTable data={ticketSalesRankData} />
        </>}
    </>
}

export default ReportTicketSoldRank