
import { Table } from "antd"
import { TableProps } from "antd/lib";

const ticketNames: { [key: number]: string } = {
    18: 'ALL PASS 1회권',
    54: 'ALL PASS 4회권',
    23: 'ALL PASS 10회권',
    24: 'ALL PASS 20회권',
    25: 'ALL PASS 40회권',
    26: 'ALL PASS 60회권',
    5: '강남 스튜디오 1회권',
    6: '강남 스튜디오 10회권',
    7: '강남 스튜디오 20회권',
    8: '강남 스튜디오 40회권',
    19: '강남 스튜디오 60회권',
    55: '강남 스튜디오 히말라야 빈야사 TTC',
    59: '선릉・도산 1회권',
    60: '선릉・도산 4회권',
    61: '선릉・도산 10회권',
    62: '선릉・도산 20회권',
};

const TICKETNAME = (id: number): string => {
    return ticketNames[id] || '알수 없음';
};

interface DataType {
    id: number
    name: string
    total_issued_tickets: number
    expired_tickets: number
    fully_used_tickets: number
    active_tickets: number
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '정기권',
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => TICKETNAME(record.id),
        sorter: (a, b) => TICKETNAME(a.id).localeCompare(TICKETNAME(b.id)),
    },
    {
        title: '사용 가능 정기권',
        key: 'active_tickets',
        dataIndex: 'active_tickets',
        sorter: (a, b) => a.active_tickets - b.active_tickets,
    },
    {
        title: '사용 불가 정기권',
        dataIndex: 'paidCount',
        key: 'paidCount',
        children: [
            {
                title: '만료',
                dataIndex: 'expired_tickets',
                key: 'expired_tickets',
                sorter: (a, b) => a.expired_tickets - b.expired_tickets,
            },
            {
                title: '모두 소진',
                dataIndex: 'fully_used_tickets',
                key: 'fully_used_tickets',
                sorter: (a, b) => a.fully_used_tickets - b.fully_used_tickets,
            },
            {
                title: '소진 비율',
                dataIndex: '',
                key: '',
                render: (q, record) => `${Math.round(record.fully_used_tickets / record.total_issued_tickets * 100).toLocaleString()}%`,
                sorter: (a, b) => (a.fully_used_tickets / a.total_issued_tickets) - (b.fully_used_tickets / b.total_issued_tickets),
            },
        ]
    },
    {
        title: '합계',
        dataIndex: 'total_issued_tickets',
        key: 'total_issued_tickets',
        sorter: (a, b) => a.total_issued_tickets - b.total_issued_tickets,
    },
];

interface IProps {
    data: Array<ITicketRetentionData>
}
const TicketRetentionReportTable = ({ data }: IProps) => {
    return <>
        <Table
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
        />
    </>
}
export default TicketRetentionReportTable