import { AxiosResponse } from 'axios';
import api from './Api'

export const createWellnessTicketGroupByWellnessTicketGroupBasicAdminRequest = (wellnessTicketGroupBasicAdminRequest: IWellnessTicketGroupBasicAdminRequest) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/admin/v2`, wellnessTicketGroupBasicAdminRequest);
}
export const getUpdateDataForWellnessTicketGroupByWellnessTicketGroupId = (wellnessTicketGroupId: string): Promise<AxiosResponse<IWellnessTicketGroupUpdateAdminResponse, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/admin/v2/update/${wellnessTicketGroupId}`)
}
export const updateWellnessTicketGroupAndOrderNumberByWellnessTicketGroupUpdateRequest = (wellnessTicketGroupUpdateRequest: IWellnessTicketGroupBasicAdminRequest) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/admin/v2`, wellnessTicketGroupUpdateRequest);
}
export const getAllWellnessTicketByCompanyIdForWellnessTicketGroup = (companyId: number): Promise<AxiosResponse<IWellnessTicketGroupCreateAdminResponse, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/admin/v2/wellness-ticket/${companyId}`)
}
export const getAllWellnessTicketGroupByCompanyId = (companyId: number): Promise<AxiosResponse<IWellnessTicketGroupListAdminResponse[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/admin/v2/${companyId}`)
}
export const deleteWellnessTicketGroupByWellnessTicketGroupId = (wellnessTicketGroupId: number): Promise<AxiosResponse<boolean>> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/wellness-ticket-group/admin/v2/${wellnessTicketGroupId}`)
}