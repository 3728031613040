import { AxiosResponse } from 'axios'
import api from './Api'

export const getWellnessClassDataForCreateLectureList = (): Promise<AxiosResponse<Array<IWellnessClassDataForCreateLectureListResponse>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-class/admin/for/create/v2`)
}

export const getWellnessClassDetailByWellnessClassId = (wellnessClassId: string): Promise<AxiosResponse<IWellnessClassDetailResponse, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/wellness-class/admin/v2?wellnessClassId=${wellnessClassId}`)
}

export const deleteWellnessLectureListAfterNowByWellnessClassId = (wellnessClassId: number): Promise<AxiosResponse<boolean, any>> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/wellness-class/admin/v2?wellnessClassId=${wellnessClassId}`)
}

export const updateWellnessLectureListWithWellnessClass = (updateWellnessLectureListWithWellnessClassRequest: IUpdateWellnessLectureListWithWellnessClassRequest): Promise<AxiosResponse<boolean, any>> => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/wellness-class/admin/v2`, updateWellnessLectureListWithWellnessClassRequest)
}

export const createWellnessClassAdmin = (createWellnessClassAdminRequest: ICreateWellnessClassAdminRequest): Promise<AxiosResponse<boolean, any>> => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/wellness-class/admin/v2`, createWellnessClassAdminRequest)
}