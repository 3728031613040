import { AxiosResponse } from 'axios';
import api from './Api'

export const createReservation = (reservation: ICreateReservation) => {
    return api.post(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/v2`, reservation);
}
export const deleteReservation = (id: string, isSend: boolean): Promise<AxiosResponse<string>> => {
    return api.delete(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/v2/${id}?isSend=${isSend}`)
};
export const updateReservationOnCheckIn = (reservation: IUpdateReservationForCheckIn) => {
    return api.put(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/v2`, reservation)
}
export const getReservationListByMember = (memberId: string, startDateTime: string, endDateTime: string, status: string): Promise<AxiosResponse<Array<IReservationByMember>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation/member/admin/v2?memberId=${memberId}&startDateTime=${encodeURIComponent(startDateTime)}&endDateTime=${encodeURIComponent(endDateTime)}&status=${status}`)
}
export const getCreateDataForReservation = (memberId: number, yogaCenterId: number, startDateTime: string, endDateTime: string): Promise<AxiosResponse<IReservationCreateAdminResponse[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/create/wellness-lecture/v2?memberId=${memberId}&yogaCenterId=${yogaCenterId}&startDateTime=${encodeURIComponent(startDateTime)}&endDateTime=${encodeURIComponent(endDateTime)}`)
}

export const getAllReservationBySearchAndWellnessLectureStartDateTimeBetween = (search: string, startDate: string, endDate: string): Promise<AxiosResponse<IReservationGetAdminResponse[]>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/v2?search=${search}&startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`)
}

export const getReservationByReservationId = (reservationId: string): Promise<AxiosResponse<IReservationGetAdminResponse>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/reservation/admin/v2/${reservationId}`)
}
