

import { Table } from "antd"
import { TableProps } from "antd/lib";
import { moneyStringFormatByNumber } from "../../../../../utils/moneyFormat";

const ticketNames: { [key: number]: string } = {
    18: 'ALL PASS 1회권',
    54: 'ALL PASS 4회권',
    23: 'ALL PASS 10회권',
    24: 'ALL PASS 20회권',
    25: 'ALL PASS 40회권',
    26: 'ALL PASS 60회권',
    5: '강남 스튜디오 1회권',
    6: '강남 스튜디오 10회권',
    7: '강남 스튜디오 20회권',
    8: '강남 스튜디오 40회권',
    19: '강남 스튜디오 60회권',
    55: '강남 스튜디오 히말라야 빈야사 TTC',
    59: '선릉・도산 1회권',
    60: '선릉・도산 4회권',
    61: '선릉・도산 10회권',
    62: '선릉・도산 20회권',
};

const TICKETNAME = (id: number): string => {
    return ticketNames[id] || '알수 없음';
};

interface DataType {
    id: number
    name: string
    price: number
    paid_tickets: number
    total_issued_tickets: number
    total_payment: number
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
    },
    {
        title: '정기권',
        dataIndex: 'name',
        key: 'name',
        render: (value, record) => TICKETNAME(record.id),
        sorter: (a, b) => TICKETNAME(a.id).localeCompare(TICKETNAME(b.id)),
    },
    {
        title: '판매가',
        dataIndex: 'price',
        key: 'price',
        render: (value) => value === 0 ? '0원' : `${moneyStringFormatByNumber(value)}원`,
        sorter: (a, b) => a.price - b.price,
    },
    {
        title: '결제 수량',
        dataIndex: 'paid_tickets',
        key: 'paid_tickets',
        sorter: (a, b) => a.paid_tickets - b.paid_tickets,
    },
    {
        title: '발급 수량',
        key: 'total_issued_tickets',
        dataIndex: 'total_issued_tickets',
        sorter: (a, b) => a.total_issued_tickets - b.total_issued_tickets,
    },
    {
        title: '판매 합계',
        key: 'total_payment',
        dataIndex: 'total_payment',
        render: (value) => value === 0 ? '0원' : `${moneyStringFormatByNumber(value)}원`,
        sorter: (a, b) => a.total_payment - b.total_payment,
    },
];

interface IProps {
    data: Array<ITicketSalesRankData>
}
const TicketSoldRankReportTable = ({ data }: IProps) => {
    return <>
        <Table
            size="small"
            columns={columns}
            dataSource={data}
            pagination={false}
            bordered
        />
    </>
}
export default TicketSoldRankReportTable