import { CheckOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";

export const wellnesTicketFrequencyUnitToKr = (frequencyUnit?: string) => {
    const frequencyUnitMap: { [key: string]: string } = {
        'DAY': '일',
        'WEEK': '주',
        'MONTH': '월',
        'YEAR': '연',
        'NOLIMIT': '-',
    };

    if (!frequencyUnit) return frequencyUnit;
    return frequencyUnitMap[frequencyUnit] ?? frequencyUnit;
}

export const wellnesTicketTypeToKr = (frequencyUnit?: string) => {
    const frequencyUnitMap: { [key: string]: string } = {
        "COUNT": "횟수",
        "PERIOD": "기간",
    };

    if (!frequencyUnit) return frequencyUnit;
    return frequencyUnitMap[frequencyUnit] ?? frequencyUnit;
}

export const wellnesTicketDiscountTypeToKr = (frequencyUnit?: string) => {
    const frequencyUnitMap: { [key: string]: string } = {
        "PERCENT": "비율",
        "FIXED": "고정",
    };

    if (!frequencyUnit) return frequencyUnit;
    return frequencyUnitMap[frequencyUnit] ?? frequencyUnit;
}
export const wellnessTicketColumns: ColumnsType<IWellnessTicketForListAdminResponse> = [
    {
        title: '이름',
        dataIndex: 'name',
        render: (q, record: IWellnessTicketForListAdminResponse) =>
            <Link to={`/wellness-ticket/${record?.id}`}>
                <div style={{ width: 'fit-content', borderRadius: 7, padding: 10, background: `linear-gradient(135deg, ${record.startColor}, ${record.endColor})`, color: record.textColor }}>
                    {`${record.name} (${wellnesTicketTypeToKr(record.type)})`}
                </div>
            </Link>,
    },
    {
        title: "사용 가능 횟수 / 사용 기한(일)", dataIndex: "remainingCnt",
        render: (value, record) => `${record.totalUsableCnt}회 / ${record.usableDate}일`
    },
    {
        title: '이용 제한',
        dataIndex: 'frequencyUnit',
        render: (value, record) => `${record.frequencyUsableCnt ? record.frequencyUsableCnt + '회 / ' : ''} ${wellnesTicketFrequencyUnitToKr(value)}`
    },
    {
        title: '판매 가격',
        dataIndex: 'price',
        render: q => q?.toLocaleString()
    },
    {
        title: '할인',
        dataIndex: 'discountType',
        render: q => wellnesTicketDiscountTypeToKr(q)
    },
    {
        title: '할인 정도',
        dataIndex: 'discountedValue',
        render: q => q?.toLocaleString()
    },
    {
        title: '할인된 가격',
        dataIndex: 'discountedPrice',
        render: q => q?.toLocaleString()
    },
    {
        title: "사용중", dataIndex: "issuedTicketCnt",
        render: (value, record) => `${value}명`
    },
    {
        title: '표시 여부',
        dataIndex: 'isDisplay',
        render: isDisplay => isDisplay ? "표시" : "숨김",
        onCell: q => ({ style: { color: q?.isDisplay ? 'green' : "black" } })
    },
    {
        title: '삭제',
        dataIndex: 'isDelete',
        render: isDelete => isDelete ? <CheckOutlined style={{ color: '#ff4d4f' }} /> : "-"
    },
]
export const issuedWellnessTicketWithMemberInfoColumns: ColumnsType<IissuedWellnessTicketWithMemberInfoAdminResponse> = [
    {
        title: "이름 (종류)", dataIndex: "name",
        render: (value, record) => <div><div style={{ width: 'fit-content', borderRadius: 7, padding: 10, background: `linear-gradient(135deg, ${record.startColor}, ${record.endColor})`, color: record.textColor }}
        >{`${value} (${wellnesTicketTypeToKr(record.type)})`}</div></div>
    },
    {
        title: "회원 (전화번호 뒤 4자리)", dataIndex: "memberName",
        render: (value, record) => `${record.memberName} (${record.memberMobile.slice(-4)})`,
        sorter: (a, b) => a.memberName ? a.memberName?.localeCompare(b.memberName ? b.memberName : '') : -1,

    },
    {
        title: "잔여 횟수 / 일수", dataIndex: "remainingCnt",
        render: (value, record) => `${record.remainingCnt}회 / ${record.remainingDate}일`
    },
    {
        title: "발급일", dataIndex: "startDate",
        sorter: (a, b) => a.startDate ? a.startDate?.localeCompare(b.startDate ? b.startDate : '') : -1,
    },
    {
        title: "사용제한", dataIndex: "",
        render: (value, record) => `${(record.frequencyUnit && record.frequencyUnit !== 'NOLIMIT' && record.frequencyUsableCnt) ? record.frequencyUsableCnt + '회 / ' : ''} ${record.frequencyUnit ? wellnesTicketFrequencyUnitToKr(record.frequencyUnit) : '-'}`
    },
    {
        title: "삭제", dataIndex: "isDelete",
        render: i => i ? "삭제" : "-"
    }
]