import { Table } from "antd"
import { TableProps } from "antd/lib";
import { moneyStringFormat, moneyStringFormatByNumber } from "../../../../../utils/moneyFormat";

interface DataType {
    // key: string;
    date: string;
    APP: number;
    SEOLLEUNG: number;
    DOSAN: number;
    GANGNAM: number;
    SITE_DISCOUNT: number;
    APP_COUPON_DISCOUNT: number;
    total: number;
}

const columns: TableProps<DataType>['columns'] = [
    {
        title: '일자',
        dataIndex: 'date',
        key: 'date',
    },
    {
        title: '인앱',
        dataIndex: 'APP',
        key: 'APP',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '선릉',
        dataIndex: 'SEOLLEUNG',
        key: 'SEOLLEUNG',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '도산',
        dataIndex: 'DOSAN',
        key: 'DOSAN',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '강남',
        key: 'GANGNAM',
        dataIndex: 'GANGNAM',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '기타',
        key: 'ETC',
        dataIndex: 'ETC',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '할인',
        key: 'SITE_DISCOUNT',
        dataIndex: 'SITE_DISCOUNT',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '쿠폰',
        key: 'APP_COUPON_DISCOUNT',
        dataIndex: 'APP_COUPON_DISCOUNT',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
    {
        title: '결제합계',
        key: 'total',
        dataIndex: 'total',
        render: (value) => value === 0 ? 0 : `${moneyStringFormatByNumber(value)}원`
    },
];

interface IProps {
    data: Array<ISalesReportData>
}
const SalesReportTableMonthly = ({ data }: IProps) => {
    const addedTotal = [...data, data.reduce((acc, cur) => ({
        date: 'total',
        APP: acc.APP + cur.APP,
        SEOLLEUNG: acc.SEOLLEUNG + cur.SEOLLEUNG,
        DOSAN: acc.DOSAN + cur.DOSAN,
        ETC: acc.ETC + cur.ETC,
        GANGNAM: acc.GANGNAM + cur.GANGNAM,
        SITE_DISCOUNT: acc.SITE_DISCOUNT + cur.SITE_DISCOUNT,
        APP_COUPON_DISCOUNT: acc.APP_COUPON_DISCOUNT + cur.APP_COUPON_DISCOUNT,
        total: acc.total + cur.total
    }))];

    return <>
        <Table
            size="small"
            columns={columns}
            dataSource={addedTotal}
            pagination={false}
            bordered
        />
    </>
}
export default SalesReportTableMonthly