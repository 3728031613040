import { DndContext, DragEndEvent, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { Table } from "antd";
import { useState } from "react";
import { TableProps } from "antd/lib";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}
interface IProps {
    dataSource: any[];
    setDataSource: Function;
    columns: any[];
    targetName?: string;
    tableOption?: TableProps | object
}

const DragAbleTable = ({ dataSource, setDataSource, columns, targetName = 'idx', tableOption }: IProps) => {
    const Row: React.FC<Readonly<RowProps>> = (props) => {
        const [isClicked, setIsClicked] = useState<boolean>(false);
        const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: props['data-row-key'], });
        const handleMouseDown = () => setIsClicked(true);
        const handleMouseUp = () => setIsClicked(false);

        const style: React.CSSProperties = {
            ...props.style,
            transform: CSS.Translate.toString(transform),
            transition,
            cursor: 'move',
            ...(isDragging ? { position: 'relative', zIndex: 2 } : {}),
        };

        return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} onMouseDown={handleMouseDown} onMouseUp={handleMouseUp} />;
    };

    const sensors = useSensors(useSensor(PointerSensor, { activationConstraint: { distance: 1, }, }),);
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setDataSource((prev: any) => {
                const activeIndex = prev.findIndex((i: any) => i.key === active.id);
                const overIndex = prev.findIndex((i: any) => i.key === over?.id);
                return arrayMove(prev, activeIndex, overIndex).map((q: any, idx) => ({ ...q, [targetName]: idx }));
            });
        }
    };

    return <>
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
            <SortableContext
                items={dataSource.map((i) => i.key)}
                strategy={verticalListSortingStrategy}
            >
                <Table
                    components={{ body: { row: Row }, }}
                    rowKey="key"
                    columns={columns}
                    dataSource={dataSource}
                    {...tableOption}
                />
            </SortableContext>
        </DndContext>
    </>
}

export default DragAbleTable;