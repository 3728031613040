import { AxiosResponse } from 'axios';
import api from '../Api'

export const getReportTicketSalesRank = (startDate: string, endDate: string): Promise<AxiosResponse<Array<ITicketSalesRankData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/ticket/sales?startDate=${startDate}&endDate=${endDate}`)
}

export const getReportTicketRetention = (): Promise<AxiosResponse<Array<ITicketRetentionData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/ticket/retention`)
}

export const getReportTicketConversion = (): Promise<AxiosResponse<Array<ITicketConversionData>, any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/ticket/conversion`)
}
