import { AxiosResponse } from 'axios';
import api from './Api'

export const requestRegisterCountAndPaidCountAndPriceByCreatedDateBetween = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/member/daily?startDate=${startDate}&endDate=${endDate}`)
}

export const requestRegisterCountAndPaidCountAndPriceToHourByCreatedDateBetween = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/member/hour?startDate=${startDate}&endDate=${endDate}`)
}

export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByCenter = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/center?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupBySignDay = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/daily?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByWeek = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/weekly?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByMonth = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/monthly?startDate=${startDate}&endDate=${endDate}`)
}
export const requestReservationCountAndStatusByCenterAndLastedUpdateDateBetweenGroupByTicket = (startDate: string, endDate: string): Promise<AxiosResponse<any[], any>> => {
    return api.get(`${process.env.REACT_APP_API_DOMAIN}/admin/report/reservation/ticket?startDate=${startDate}&endDate=${endDate}`)
}

