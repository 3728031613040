import { Column } from '@ant-design/plots';

interface IProps {
    value?: any[]
}

const MemberReportDailyChart = ({ value }: IProps) => {
    const config = {
        data: {
            value: value ?? [{
                "name": "신규 가입자",
                "day": "월요일",
                "count": 18.9
            },
            {
                "name": "결제 건수",
                "day": "월요일",
                "count": 5
            },
            {
                "name": "신규 가입자",
                "day": "화요일",
                "count": 38.9
            },
            {
                "name": "결제 건수",
                "day": "화요일",
                "count": 15
            },
            {
                "name": "신규 가입자",
                "day": "수요일",
                "count": 32.9
            },
            {
                "name": "결제 건수",
                "day": "수요일",
                "count": 10
            },
            {
                "name": "신규 가입자",
                "day": "목요일",
                "count": 22.9
            },
            {
                "name": "결제 건수",
                "day": "목요일",
                "count": 30
            },
            {
                "name": "신규 가입자",
                "day": "금요일",
                "count": 22.9
            },
            {
                "name": "결제 건수",
                "day": "금요일",
                "count": 30
            },
            {
                "name": "신규 가입자",
                "day": "토요일",
                "count": 22.9
            },
            {
                "name": "결제 건수",
                "day": "토요일",
                "count": 30
            },
            {
                "name": "신규 가입자",
                "day": "일요일",
                "count": 22.9
            },
            {
                "name": "결제 건수",
                "day": "일요일",
                "count": 30
            }]
        },
        xField: 'day',
        yField: 'count',
        colorField: 'name',
        group: true,
        style: {
            inset: 5,
        },
    };
    return <Column {...config} />;
}

export default MemberReportDailyChart